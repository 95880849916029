<template>
  <div class="locale-select">
    <el-select
      v-model="selected"
      :placeholder="$t('global.language')"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        <gb-flag
          :code="item.icon"
          size="small"
        /><span>{{ item.label }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'locale-select',
  data () {
    return {
      selected: localStorage.getItem('locale') || 'nl-NL',
      options: [
        { label: 'Nederlands', value: 'nl-NL', icon: 'nl' },
        { label: 'English', value: 'en-US', icon: 'gb' }
      ]
    }
  },
  watch: {
    selected: function (value) {
      this.$i18n.locale = value
      localStorage.setItem('locale', value)
    }
  }
}
</script>

<style>
.gb-flag {
  vertical-align: middle;
  margin-right: 15px;
}
</style>
