<template>
  <div
    v-loading="loading"
    class="document"
  >
    <el-row type="flex">
      <el-col
        v-if="folders.length > 1"
        :xs="24"
        :sm="7"
        :lg="4"
      >
        <tree></tree>
      </el-col>
      <el-col
        class="grow"
        :xs="24"
        :sm="12"
        :lg="14"
      >
        <div class="document-view">
          <breadcrumbs></breadcrumbs>
          <h1
            v-if="!edit"
            class="title"
          >{{document.title}}</h1>
          <div
            v-if="!edit"
            class="contents"
            v-dompurify-html="document.contents"
          ></div>
          <div v-else>
            <el-input
              class="title-edit"
              v-model="document.title"
            ></el-input>
            <tinymce-edit
              :document="this.document"
              :user="this.currentUser"
              @save-document="saveDocument(true)"
            ></tinymce-edit>
          </div>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="7"
        :lg="6"
      >
        <docInfo @save-document="saveDocument"></docInfo>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import tinymceEdit from '../components/document/TinyMCE-Edit'
import docInfo from '../components/document/DocInfo'
import tree from '../components/navigation/Tree'
import breadcrumbs from '../components/documents/BreadCrumbs'
import mixin from '../mixins'
import { mapState } from 'vuex'

export default {
  name: 'document',
  mixins: [mixin],
  components: {
    tree,
    breadcrumbs,
    docInfo,
    tinymceEdit
  },
  data () {
    return {
      loading: true,
      timer: null
    }
  },
  computed: mapState({
    currentUser: state => state.users.current_user,
    edit: state => state.documents.edit,
    document: state => state.documents.document,
    folders: state => state.folders.all ? state.folders.all : null
  }),
  methods: {
    saveDocument (autosave = false) {
      this.$store.dispatch('documents/save', this.document)
        .then(response => {
          if (response.ok) {
            if (!autosave) {
              this.$notify.success({
                title: this.$t('messages.success'),
                message: this.$t('global.type_is_saved', { type: this.$tc('global.document') }),
                position: 'bottom-right',
                duration: 2000
              })
              // redirect if route changed
              if (
                this.document.full_slug !== response.data.document.full_slug &&
                this.$router.currentRoute.slug !== response.data.document.full_slug
              ) {
                this.$router.push(response.data.document.full_slug)
              }
            }
          } else {
            if (response.data.document) {
              this.error()
            }
          }
        })
        .catch(_ => this.error())
    },
    update () {
      if (this.$route.params.slug) {
        this.loading = true
        let parentSlug = this.$route.params.slug.split('/').slice(-2)[0]
        let lastSlug = this.last(this.$route.params.slug.split('/'))
        this.$store.dispatch('documents/slug', { slug: lastSlug, parent_slug: parentSlug }).then(_ => { this.loading = false })
      }
    }
  },
  mounted () {
    this.loading = true
    if (this.documents != null) {
      this.$store.dispatch('documents/all')
    }
    if (this.folders) {
      this.$store.dispatch('folders/all')
    }
    let parentSlug = this.$route.params.slug.split('/').slice(-2)[0]
    let lastSlug = this.last(this.$route.params.slug.split('/'))
    this.$store.dispatch('documents/slug', { slug: lastSlug, parent_slug: parentSlug }).then(_ => { this.loading = false })
  },
  watch: {
    $route (to, from) {
      this.update()
    }
  }
}
</script>

<style lang="scss">
.document {
  width: 100%;
  background-color: $template-color-background;

  .grow {
    flex-grow: 1;
  }

  .el-row {
    @include media(">tablet") {
      height: auto;
      min-height: 100%;
      flex-flow: nowrap;
    }
    flex-flow: wrap;
    align-items: strech;
  }

  .document-view {
    padding: 25px;

    .title {
      padding: 5px;
    }

    .title-edit {
      border-radius: 0;
      border-bottom: 0;
      font-weight: bold;
      font-size: 2em;
    }

    .contents {
      padding: 5px;

      p {
        font-family: initial;
        color: initial;
        line-height: 1.4;
      }

      strong {
        font-weight: bold;
      }

      img {
        margin: 20px 0;
        max-width: 100%;
        height: auto;
      }
    }

    .submitbtn {
      display: none;
    }
  }
}
</style>
