import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

// edit is the state of the client (is he editing the current document yes/no)
const state = {
  edit: false,
  document: {
    id: null,
    folder_id: null,
    user_id: null,
    title: null,
    contents: null,
    visible: false,
    archive_number: null,
    archived_at: null,
    created_at: null,
    updated_at: null,
    slug: null
  },
  all: []
}

const getters = {}

const actions = {
  edit ({ commit }, editState) {
    commit('setEdit', editState)
  },
  resetDocument ({ commit }) {
    commit('setDocument', {
      id: null,
      folder_id: null,
      user_id: null,
      title: null,
      contents: null,
      visible: false,
      archive_number: null,
      archived_at: null,
      created_at: null,
      updated_at: null,
      slug: null
    })
  },
  get ({ commit }, id) {
    return new Promise(function (resolve, reject) {
      Vue.http.get('documents/' + id)
        .then(response => {
          commit('setDocument', response.data.document)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  all ({ commit }) {
    return new Promise(function (resolve, reject) {
      Vue.http.get('documents')
        .then(response => {
          commit('setDocuments', response.data.documents)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  slug ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('documents/slug', data)
        .then(response => {
          commit('setDocument', response.data.document)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  create ({ commit, dispatch }, document) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('documents', document)
        .then(response => {
          dispatch('all')
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  save ({ commit, dispatch }, document) {
    return new Promise(function (resolve, reject) {
      Vue.http.patch('documents/' + document.id, document)
        .then(response => {
          dispatch('all')
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  delete ({ commit, dispatch }, id) {
    return new Promise(function (resolve, reject) {
      Vue.http.delete('documents/' + id, document)
        .then(response => {
          dispatch('all')
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  archive ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('documents/' + data.id + '/archive', data)
        .then(response => {
          commit('setDocument', response.data.document)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

const mutations = {
  setDocument (state, document) {
    state.document = document
  },
  setDocuments (state, documents) {
    state.all = documents
  },
  setEdit (state, edit) {
    state.edit = edit
  }
}

export default {
  namespaced: true,
  plugins: [
    VuexReset()
  ],
  state,
  getters,
  actions,
  mutations
}
