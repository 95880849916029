var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tinymce-edit"},[_c('tinymce-editor',{attrs:{"id":"tiny-mce-editor","api-key":this.tinyKey,"init":{
      plugins: ['lists','wordcount', 'autoresize', 'autolink', 'code', 'fullscreen', 'image', 'link', 'table', 'toc'],
      external_plugins: {'wave': 'https://cdn2.codox.io/waveTinymce/plugin.min.js'},
      wave: _vm.codexConfig,
      toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
      images_upload_url: _vm.uploadUrl,
      imagetools_cors_hosts: ['localhost:8080', 'localhost:3000'],
      mobile: {
        theme: 'mobile',
        plugins: ['lists', 'autolink'],
        toolbar: ['undo', 'bold', 'italic', 'styleselect']
      },
      font_formats: 'Roboto=Roboto-Regular, Helvetica, sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
      image_title: true,
      image_list: this.images,
      images_upload_handler: this.uploadImage,
      min_height: 500,
      setup: _vm.setupDefaults
    }},on:{"onChange":function($event){return _vm.save(6000)}},model:{value:(_vm.document.contents),callback:function ($$v) {_vm.$set(_vm.document, "contents", $$v)},expression:"document.contents"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }