import Vue from 'vue'
import Vuex from 'vuex'
import VuexReset from '@ianwalter/vuex-reset'
import accesses from './modules/accesses'
import documents from './modules/documents'
import folders from './modules/folders'
import organizations from './modules/organizations'
import users from './modules/users'
import search from './modules/search'
import convert from './modules/convert'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    VuexReset()
  ],
  mutations: {
    reset: () => { }
  },
  modules: {
    accesses,
    auth,
    documents,
    folders,
    organizations,
    users,
    search,
    convert
  }
})
