<template>
  <div class="user">
    <div class="inner-margin">
      <div class="page-card">
        <h1>{{$t('global.account') + ' ' + $t('global.settings')}}</h1>
        <el-row type="flex">
          <el-col
            :xs="24"
            :sm="12"
            :lg="12"
          >
            <el-form
              ref="userForm"
              :model="user"
              :rules="rules"
              @submit.prevent="submitForm"
              autocomplete="false"
              label-position="top"
            >
              <el-form-item
                :label="$t('user.name')"
                prop="name"
              >
                <el-input
                  name="name"
                  :placeholder="$t('user.name')"
                  v-model="user.name"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('user.email')"
                prop="email"
              >
                <el-input
                  name="email"
                  :placeholder="$t('user.email')"
                  type="email"
                  v-model="user.email"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('user.password')"
                prop="password"
              >
                <el-input
                  name="password"
                  :placeholder="$t('user.password')"
                  type="password"
                  v-model="user.password"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('user.password_validation')"
                prop="password_confirmation"
              >
                <el-input
                  name="password_confirmation"
                  :placeholder="$t('user.password_validation')"
                  type="password"
                  v-model="user.password_confirmation"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('global.language')">
                <locale-select />
              </el-form-item>
              <el-form-item :label="$t('user.two_factor_authentication')">
                <el-tooltip placement="top">
                  <div
                    slot="content"
                    v-html="$t('user.two_factor_authentication_tooltip')"
                  ></div>
                  <el-switch
                    v-model="user.secret_set"
                    active-text="On"
                    inactive-text="Off"
                    @change="updateTwoFactor"
                  >
                  </el-switch>
                </el-tooltip>
              </el-form-item>
              <el-form-item>
                <el-button
                  @click.prevent="submitForm"
                  native-type="submit"
                  type="primary"
                  class="user-save"
                  round
                >{{$t('global.save')}}</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col
            :xs="24"
            :sm="12"
            :lg="12"
          >
            <el-tooltip
              placement="top"
              :content="$t('user.new_image')"
            >
              <el-upload
                drag
                class="avatar-uploader"
                v-loading="imageUploadLoading"
                :action="image_upload_url"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :headers="{ 'Authorization': 'Bearer ' + jwt }"
              >
                <img
                  v-if="image_url"
                  :src="image_url"
                  class="avatar"
                >
                <i
                  v-else
                  class="el-icon-upload avatar-uploader-icon"
                ></i>
              </el-upload>
            </el-tooltip>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../mixins'
import localeSelect from '../components/navigation/LocaleSelect'
import { mapState } from 'vuex'

export default {
  name: 'user',
  mixins: [mixin],
  components: {
    localeSelect
  },
  data () {
    var checkDuplicatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('messages.fill_same_password_twice')))
      } else if (this.user.password !== null && value !== this.user.password) {
        callback(new Error(this.$t('messages.passwords_not_matching')))
      } else {
        callback()
      }
    }
    return {
      rules: {
        name: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.name') }) }
        ],
        email: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.email') }) },
          { type: 'email', message: this.$t('global.fill_in_a_valid_type', { type: this.$t('user.email') }) }
        ],
        password_confirmation: [
          { validator: checkDuplicatePassword }
        ]
      },
      jwt: localStorage.getItem('jwt'),
      imageUploadLoading: false,
      showQR: false
    }
  },
  computed: mapState({
    user: state => state.users.current_user,
    image_url: function (state) { return this.getImageOrigin(state.users.current_user.avatar) },
    image_upload_url: state => process.env.VUE_APP_DEFAULT_ROOT + '/users/' + state.users.current_user.id + '/avatar'
  }),
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      let data = JSON.parse(atob(localStorage.getItem('jwt').split('.')[1]))
      this.$store.dispatch('users/getCurrentUser', data.sub).then(_ => {
        this.$notify.success({
          title: this.$t('messages.success'),
          message: this.$t('user.avatar_uploaded'),
          position: 'bottom-right'
        })
        this.imageUploadLoading = false
      })
    },
    beforeAvatarUpload (file) {
      this.imageUploadLoading = true
      const isCorrectFormat = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(file.type)
      const isCorrectSize = file.size / 1024 / 1024 < 2
      if (!isCorrectFormat) {
        this.imageUploadLoading = false
        this.$notify.error({
          title: this.$t('messages.error'),
          message: this.$t('messages.wrong_file_type_images_tooltip'),
          position: 'bottom-right'
        })
      }
      if (!isCorrectSize) {
        this.imageUploadLoading = false
        this.$notify.error({
          title: this.$t('messages.error'),
          message: this.$t('messages.image_to_big_tooltip'),
          position: 'bottom-right'
        })
      }
      return isCorrectFormat && isCorrectSize
    },
    submitForm () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('users/save', this.user)
            .then(_ => this.$notify.success({
              title: this.$t('messages.success'),
              message: this.$t('global.type_is_saved', { type: this.$tc('global.user') }),
              position: 'bottom-right'
            }))
            .catch(_ => this.error())
        } else {
          return false
        }
      })
    },
    updateTwoFactor (state) {
      let url = state ? 'auth/enable' : 'auth/disable'
      this.$store.dispatch(url)
        .then(response => {
          if (response.ok) {
            this.$notify({
              type: (state ? 'success' : 'warning'),
              title: (state ? this.$t('messages.success') : this.$t('messages.warning')),
              message: (state ? this.$t('messages.two_factor_authentication_on') : this.$t('messages.two_factor_authentication_off')),
              position: 'bottom-right'
            })
          }
        })
        .catch(_ => this.error())
    }
  }
}
</script>

<style lang="scss">
.inner-margin {
  margin: 25px;
}

.user {
  background-color: $template-color-background-dark;

  .el-row {
    flex-flow: wrap;
  }

  .el-form-item:last-child {
    margin-bottom: 0;
  }

  .el-upload {
    display: block;
  }
  .user-save {
    width: 100%;

    @include media(">tablet") {
      margin-top: 20px;
      width: auto;
    }
  }

  .qr-code {
    text-align: center;

    p {
      color: $template-color-text;
      font-family: "Roboto-Bold", Helvetica, sans-serif;
      font-size: 1.25em;
      padding: 0 25px;
      font-weight: bold;
    }

    img {
      width: 100%;
      max-width: 350px;
      padding: 40px;
      box-sizing: border-box;
    }
  }

  .avatar-uploader {
    max-width: 256px;
    margin: 20px auto;

    @include media(">tablet") {
      padding: 40px;
    }
    .el-upload-dragger {
      width: 100%;
      height: 100%;
    }
    .el-upload-dragger:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      color: #8c939d;
      width: 100%;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 100%;
      display: block;
    }
  }
}
</style>
