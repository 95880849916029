import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from '../views/Authentication.vue'
import Documents from '../views/Documents.vue'
import Document from '../views/Document.vue'
import User from '../views/User.vue'
import Organizations from '../views/Organizations.vue'
import Error from '../views/Error.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'authentication',
    component: Authentication,
    alias: ['/sign-in', '/register', '/forgot-password']
  },
  {
    path: '/two-factor',
    name: 'two_factor',
    component: Authentication
  },
  {
    path: '/validate/:email_validate_token',
    name: 'authentication_validate',
    component: Authentication
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents
  },
  {
    path: '/documents/:slug+/token/:token',
    name: 'documents_slug_token',
    component: Documents
  },
  {
    path: '/documents/:slug+',
    name: 'documents_slug',
    component: Documents
  },
  {
    path: '/document/:slug+/token/:token',
    name: 'document_slug_token',
    component: Document
  },
  {
    path: '/document/:slug+',
    name: 'document_slug',
    component: Document
  },
  {
    path: '/user',
    name: 'user',
    component: User
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: Organizations
  },
  {
    path: '/organizations/:query',
    name: 'organizations_query',
    component: Organizations
  },
  {
    path: '*',
    name: 'catch_all',
    component: Error
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
