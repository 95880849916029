<template>
  <div class="authentication">
    <div v-if="currentPath == '/register'">
      <register />
    </div>
    <div v-else-if="currentPath == '/forgot-password'">
      <forgot-password />
    </div>
    <div v-else-if="currentPath == '/two-factor'">
      <two-factor />
    </div>
    <div v-else>
      <sign-in />
    </div>
  </div>
</template>

<script>
import signIn from '../components/authentication/SignIn'
import register from '../components/authentication/Register'
import forgotPassword from '../components/authentication/ForgotPassword'
import twoFactor from '../components/authentication/TwoFactor'
import { mapState } from 'vuex'

export default {
  name: 'authentication',
  components: {
    signIn,
    register,
    forgotPassword,
    twoFactor
  },
  data () {
    return {
      currentPath: this.$router.currentRoute.path
    }
  },
  computed: mapState({
    user: state => state.users.current_user
  }),
  watch: {
    $route: function (newRoute) {
      this.currentPath = newRoute.path
    }
  },
  mounted () {
    if (this.user && this.user.id != null) {
      this.$router.push('/documents')
    }
    // check if the user tries to validate his email adress
    let token = this.$route.params['email_validate_token']
    if (token) {
      this.$store.dispatch('users/validate', token)
        .then(_ => this.$notify.success({
          title: this.$t('messages.success'),
          message: this.$t('authentication.can_now_sign_in'),
          position: 'bottom-right'
        }))
        .catch(_ => this.$notify.error({
          title: this.$t('messages.error'),
          message: this.$t('authentication.combination_not_found'),
          position: 'bottom-right'
        }))
    }
  }
}
</script>

<style lang="scss">
.authentication {
  height: 100%;
}

.auth-container {
  background-color: $template-color-background;
  padding: 40px 40px;
  @include border-radius(15px);
  @include box-shadow(2px 2px 10px 0px rgba(0, 0, 0, 0.25));

  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translate(0, -50%);

  @include media(">=tablet") {
    padding: 40px 100px;
    min-width: 400px;
    max-width: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  h1 {
    text-align: center;
  }

  label {
    padding: 0;
    font-family: "Roboto-Bold", Helvetica, sans-serif;
    font-size: 20px;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"] {
    border: 0;
    border-bottom: 1px solid #b1b7bb;
    @include border-radius(0);
  }

  .el-button {
    width: 100%;
    background-color: $template-color-first;
    border-color: $template-color-first;
    font-family: "Roboto-Bold", Helvetica, sans-serif;
    font-size: 14px;

    &:hover {
      background-color: lighten($template-color-first, 10%);
      border-color: lighten($template-color-first, 10%);
    }
  }

  .link-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .link {
      color: $template-color-first;
    }
  }
}
</style>
