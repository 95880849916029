<template>
  <div class="convert">
    <el-dropdown
      :loading="loading"
      @command="download"
    >
      <el-button
        type="primary"
        round
      >
        <font-awesome-icon :icon="['fa', 'download']" /> {{$t('global.download')}}
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="type in types"
          :key="type.label"
          :command="type.command"
        >
          {{type.label}} {{$tc('global.document')}}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'convert',
  mixins: [mixin],
  data () {
    return {
      loading: false,
      types: [
        { label: 'Word', command: 'docx' },
        { label: 'PDF', command: 'pdf' },
        { label: 'Markup', command: 'markdown' }
      ]
    }
  },
  computed: mapState({
    document: state => state.documents.document ? state.documents.document : null
  }),
  methods: {
    download (type) {
      this.loading = true
      this.$store.dispatch('convert/download', { id: this.document.id, type: type })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.setAttribute('download', this.document.title + '.' + type)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(response => {
          this.message('error', response.data.errors)
        })
        .finally(_ => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.convert {
  margin-top: 12px;

  .el-button,
  .el-dropdown {
    width: 100%;
  }
}
</style>
