<template>
  <div class="dropdown">
    <div
      class="header"
      @click="toggle"
      :class="this.active ? 'active' : ''"
    >
      <div class="arrow">
        <font-awesome-icon
          :class="this.active ? 'active' : ''"
          :icon="['fas', 'chevron-right']"
        />
      </div>
      <slot name="header"></slot>
    </div>
    <div
      ref="content"
      :class="this.active ? 'active content' : 'content'"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'dropdown',
  props: {
    activeState: Boolean
  },
  data () {
    return {
      active: this.activeState,
      height: 0
    }
  },
  methods: {
    toggle () {
      this.active = !this.active
      this.set(this.active)
    },
    set (status) {
      this.active = status
      if (status) {
        this.resizeDropDown()
      } else {
        this.$refs.content.style.height = '0px'
      }
    },
    resizeDropDown () {
      if (this.active) {
        this.$refs.content.style.height = 'auto'
        this.height = this.$refs.content.offsetHeight
        this.$refs.content.style.height = '0px'
        setTimeout(_ => {
          this.$refs.content.style.height = this.height + 'px'
        }, 0)
      }
    }
  },
  created () {
    window.addEventListener('resize', this.resizeDropDown)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeDropDown)
  },
  mounted () {
    if (this.active) {
      setTimeout(_ => {
        this.set(this.active)
      }, 0)
    }
  },
  watch: {
    activeState: function (newState) {
      this.set(newState)
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  position: relative;
  background-color: #ffffff;
  @include border-radius(6px);

  &:not(first-child) {
    margin-top: 25px;
  }

  .header {
    padding: 24px;
    &:hover {
      cursor: pointer;
    }
  }

  .arrow {
    position: absolute;
    top: 40px;
    right: 26px;

    svg {
      @include transition(transform 0.3s ease);
      color: $template-color-text-light;
      font-size: 18px;
      transform: rotate(-0.25turn);

      &.active {
        transform: rotate(0.25turn);
      }
    }
  }

  .content {
    height: 0;
    overflow: hidden;
    @include transition(height 0.3s ease);

    &.active {
      height: auto;
      @include border-radius(6px);
    }
  }
}
</style>
