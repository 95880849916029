<template>
  <div class="document-line">
    <div
      class="link"
      @click="goto('/document/' + $route.params.slug + '/' + document.slug)"
    >
      <font-awesome-icon :icon="['fas', 'file-alt']" />
      {{capitalizeFirstLetter(document.title)}}
      <el-tooltip
        placement="top"
        :content="$t('document.invisable_full')"
      >
        <font-awesome-icon
          class="document-icon eye"
          v-if="!document.visible"
          :icon="['fas', 'eye-slash']"
        />
      </el-tooltip>
      <el-tooltip
        placement="top"
        :content="$t('document.archived_full', { prefix: this.capitalizeFirstLetter($tc('document.prefix_it')) ,type: $tc('global.document') })"
      >
        <font-awesome-icon
          class="document-icon lock"
          v-if="document.archive_number"
          :icon="['fas', 'lock']"
        />
      </el-tooltip>
    </div>
    <el-button-group
      v-if="this.userHasRights(this.current_user, 'writer') || this.current_user.name == document.user"
      class="document-buttons"
    >
      <el-button
        round
        size="medium"
        @click="goto('/document/' + $route.params.slug + '/' + document.slug, true)"
      >Bewerk</el-button>
      <el-button
        round
        size="medium"
        type="danger"
        @click="delDocument(document.id)"
      >Verwijder</el-button>
    </el-button-group>
  </div>
</template>

<script>
import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'document-line',
  mixins: [mixin],
  props: {
    document: Object
  },
  computed: mapState({
    current_user: state => state.users.current_user
  }),
  methods: {
    goto (link, openEdit = false) {
      this.$store.dispatch('documents/resetDocument').then(_ => {
        this.$store.dispatch('documents/edit', openEdit)
        this.$router.push(link)
      })
    },
    delDocument (id) {
      this.$confirm(this.$t('global.type_delete_question', { prefix: this.$t('document.prefix_it'), type: this.$tc('global.document') }), this.$t('global.attention'), {
        confirmButtonText: this.$t('global.delete'),
        cancelButtonText: this.$t('global.cancel'),
        type: 'error',
        roundButton: true,
        center: true
      }).then(_ => {
        this.$store.dispatch('documents/delete', id).then(_ => {
          this.$emit('update')
          this.$notify.success({
            title: this.$t('messages.success'),
            message: this.$t('global.type_is_deleted', { type: this.$tc('global.document') }),
            position: 'bottom-right'
          })
        }).catch(response => {
          this.$notify.error({
            title: this.$t('messages.error'),
            message: response.data.response ? response.data.response : this.$t('messages.generic_help_tooltip'),
            position: 'bottom-right'
          })
        })
      })
    }
  }
}
</script>

<style lang="scss">
.document-line {
  .link {
    .document-buttons {
      margin-right: 5px;
    }

    svg {
      font-size: 1.4em;
      margin-right: 10px;
    }

    .document-icon {
      margin-left: 10px;
      font-size: 1.1em;

      &.lock {
        color: #ffa000;
      }

      &.eye {
        color: $template-color-first;
      }
    }
  }
}
</style>
