import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

const newOrganization = {
  id: null,
  name: null,
  logo: null,
  created_at: null,
  updated_at: null
}

const state = {
  organization: {
    id: null,
    name: null,
    logo: null,
    created_at: null,
    updated_at: null
  },
  all: []
}

const getters = {}

const actions = {
  reset ({ commit }) {
    return new Promise(function (resolve, reject) {
      commit('setOrganization', newOrganization)
      resolve()
    })
  },
  get ({ commit }, id) {
    return new Promise(function (resolve, reject) {
      Vue.http.get('organizations/' + id)
        .then(response => {
          commit('setOrganization', response.data.organization)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  all ({ commit }) {
    return new Promise(function (resolve, reject) {
      Vue.http.get('organizations')
        .then(response => {
          commit('setOrganizations', response.data.organizations)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  create ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('organizations', { name: data.name, user_id: data.userId })
        .then(response => {
          commit('setOrganization', response.data.organization)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  save ({ commit }, organization) {
    return new Promise(function (resolve, reject) {
      Vue.http.patch('organizations/' + organization.id, organization)
        .then(response => {
          commit('setOrganization', response.data.organization)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

const mutations = {
  setOrganization (state, organization) {
    state.organization = organization
  },
  setOrganizations (state, organizations) {
    state.all = organizations
  }
}

export default {
  namespaced: true,
  plugins: [
    VuexReset()
  ],
  state,
  getters,
  actions,
  mutations
}
