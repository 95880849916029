import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

const state = {
  access: {
    user_id: null,
    orgnanization_id: null,
    folder_id: null,
    document_id: null
  },
  all: []
}

const getters = {}

const actions = {
  all ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .get('access/' + data.type + '/' + data.id)
        .then(response => {
          commit('setAccesses', response.data.accesses)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  create ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('access', data)
        .then(response => {
          let objectId = data.document_id ? data.document_id : data.folder_id
          let type = data.document_id ? 'document' : 'folder'
          dispatch('all', { type: type, id: objectId })
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  delete ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .delete('access/' + data.type + '/' + data.id + '/' + data.sort + '/' + data.sort_id + '/')
        .then(response => {
          if (response.ok && !response.response) {
            dispatch('all', { type: data.type, id: data.id })
            resolve(response)
          }
          reject(response)
        })
        .catch(e => reject(e))
    })
  }
}

const mutations = {
  setAccess (state, access) {
    state.access = access
  },
  setAccesses (state, accesses) {
    state.all = accesses
  }
}

export default {
  namespaced: true,
  plugins: [VuexReset()],
  state,
  getters,
  actions,
  mutations
}
