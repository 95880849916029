<template>
  <div class="component-header">
    <search></search>
    <div class="user-info">
      <el-tooltip
        placement="top"
        :disabled="!isUser()"
        :content="$t('header.user_hover_tooltip')"
      >
        <router-link
          :class="!isUser() ? 'disabled' : ''"
          :event="!isUser() ? '' : 'click'"
          to="/user"
        >
          <p class="user-greating">{{$t("global.hello")}} <span class="user-name">{{current_user ? this.getFirstWord(current_user.name) : null}}</span></p>
          <img
            class="user-image"
            :src="getImageOrigin(current_user.avatar, require('../../assets/images/user-icon.png'))"
            alt="User image"
          />
        </router-link>
      </el-tooltip>
      <div
        class="logout"
        @click="logout"
      >
        <span class="text">{{$t('global.sign_out')}}</span>
        <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
      </div>
    </div>
  </div>
</template>

<script>
import search from '../navigation/Search.vue'
import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'component-header',
  mixins: [mixin],
  computed: mapState({
    current_user: state => state.users.current_user,
    username: function (state) { return state.users.current_user.name ? state.users.current_user.name : this.$t('global.role_guest') }
  }),
  components: {
    search
  },
  methods: {
    isUser () {
      return this.current_user.name
    },
    logout: function () {
      if (this.isUser()) {
        this.$store.commit('reset')
        localStorage.removeItem('jwt')
        this.$router.push('/sign-in')
      } else {
        // TODO make register link for specific organization?
        this.$router.push('/register')
      }
    }
  }
}
</script>

<style lang="scss">
.component-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $template-color-background;
  border-bottom: 2px $template-color-background-dark solid;

  .user-info {
    display: flex;
    align-items: center;
    color: $template-color-text-light;

    a {
      height: 80px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      color: $template-color-text-light;
      text-decoration: none;
      @include transition(all 0.3s ease);

      &.disabled {
        cursor: default;
      }
      &.active:hover {
        background-color: $template-color-background-hover;
      }

      .user-greating {
        text-align: center;
        .user-name {
          color: $template-color-text;
          font-weight: bold;
        }
      }

      .user-image {
        width: 60px;
        height: 60px;
        margin-left: 20px;
        @include border-radius(100%);
      }
    }

    .logout {
      display: flex;
      .text {
        margin: 0 10px;
        display: none;
        @include media(">tablet") {
          display: block;
        }
      }
      color: $template-color-text-light;
      margin-right: 25px;
      @include transition(all 0.3s ease);

      svg {
        margin-top: 2px;
        margin-left: 5px;
        @include transition(margin-left 0.3s ease);
      }
      &:hover {
        margin-right: 20px;
        cursor: pointer;
        color: $template-color-first;
      }
      &:hover svg {
        margin-left: 10px;
      }
    }
  }
}
</style>
