<template>
  <div class="user-dialog">
    <el-dialog
      :title="$tc('global.user', 1)"
      :visible.sync="dialogVisible"
      :beforeClose="closeDialog"
    >
      <el-row
        class="dialog-container"
        type="flex"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :lg="10"
        >
          <el-form
            ref="userForm"
            :model="user"
            :rules="rules"
            autocomplete="false"
            @submit.prevent="saveUser"
          >
            <el-form-item
              label="Rol"
              prop="role"
            >
              <el-select
                name="role"
                v-model="user.role"
                :placeholder="$t('user.role')"
              >
                <el-option
                  v-for="role in roles"
                  :key="role"
                  :label="translateRole(role)"
                  :value="role"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('user.name')"
              prop="name"
            >
              <el-input
                name="name"
                type="text"
                v-model="user.name"
                :placeholder="$t('global.fill_in_your_type', { type: $t('user.name')})"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('user.email')"
              prop="email"
            >
              <el-input
                name="email"
                type="email"
                v-model="user.email"
                :placeholder="$t('global.fill_in_your_type', { type: $t('user.email')})"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('user.password')"
              prop="password"
            >
              <el-input
                name="password"
                type="password"
                v-model="user.password"
                :placeholder="$t('global.fill_in_your_type', { type: $t('user.password')})"
                show-password
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('user.password_validation')"
              name="password_confirmation"
              prop="password_confirmation"
            >
              <el-input
                name="password_validation"
                type="password"
                v-model="user.password_confirmation"
                :placeholder="$t('global.fill_in_your_type', { type: $t('user.password_validation')})"
                show-password
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :lg="14"
        >
          <el-upload
            drag
            v-show="user.id"
            class="avatar-uploader"
            v-loading="imageUploadLoading"
            :action="image_upload_url"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :headers="{ 'Authorization': 'Bearer ' + jwt }"
          >
            <img
              v-if="user.avatar"
              :src="getImageOrigin(user.avatar)"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-upload avatar-uploader-icon"
            ></i>
          </el-upload>
        </el-col>
        <slot name="footer">
          <div class="dialog-footer">
            <el-button @click="$emit('close-dialog')">{{$t('global.cancel')}}</el-button>
            <el-button
              :loading="saveLoading"
              @click.prevent="saveUser"
              native-type="submit"
              type="primary"
            >{{$t('global.save')}}</el-button>
          </div>
        </slot>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '../../mixins'

export default {
  name: 'user-dialog',
  mixins: [mixin],
  props: {
    show: String,
    user: Object
  },
  data () {
    var checkDuplicatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('user.password_validation_empty')))
      } else if (value < 6) {
        callback(new Error(this.$t('user.password_to_short')))
      } else if (value !== this.user.password) {
        callback(new Error(this.$t('user.passwords_not_matching')))
      } else {
        callback()
      }
    }
    return {
      rules: {
        role: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.role') }) }
        ],
        name: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.name') }) }
        ],
        email: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.email') }) },
          { type: 'email', message: this.$t('global.fill_in_a_valid_type', { type: this.$t('user.email') }) }
        ],
        password: [
          { min: 6, message: this.$t('user.password_to_short') },
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.password') }) }
        ],
        password_confirmation: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.password') }) },
          { validator: checkDuplicatePassword }
        ]
      },
      jwt: localStorage.getItem('jwt'),
      imageUploadLoading: false,
      saveLoading: false
    }
  },
  computed: mapState({
    dialogVisible: function () { return this.show === 'user' },
    current_user: state => state.users.current_user,
    organization: state => state.organizations.organization,
    image_upload_url: state => process.env.VUE_APP_DEFAULT_ROOT + '/users/' + state.users.user.id + '/avatar'
  }),
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.$store.dispatch('users/get', this.$store.state.users.user.id)
        .then(_ => {
          if (this.current_user.id === this.$store.state.users.user.id) {
            this.$store.dispatch('users/getCurrentUser', this.user.id)
          }
          this.$notify.success({
            title: this.$t('messages.success'),
            message: this.$t('user.avatar_uploaded'),
            position: 'bottom-right'
          })
          this.imageUploadLoading = false
        })
        .catch(_ => this.error())
    },
    beforeAvatarUpload (file) {
      this.imageUploadLoading = true
      const isCorrectFormat = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(file.type)
      const isCorrectSize = file.size / 1024 / 1024 < 2
      if (!isCorrectFormat) {
        this.$notify.error({
          title: this.$t('messages.error'),
          message: this.$t('messages.wrong_file_type_images_tooltip'),
          position: 'bottom-right'
        })
      }
      if (!isCorrectSize) {
        this.$notify.error({
          title: this.$t('messages.error'),
          message: this.$t('messages.image_to_big_tooltip'),
          position: 'bottom-right'
        })
      }
      return isCorrectFormat && isCorrectSize
    },
    saveUser () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true
          if (this.user.id) {
            this.$store.dispatch('users/save', this.user)
              .then(response => {
                if (response.ok) {
                  this.saveLoading = false
                  this.$notify.success({
                    title: this.$t('messages.success'),
                    message: this.$t('global.type_is_saved', { type: this.$tc('global.user') }),
                    position: 'bottom-right'
                  })
                  if (this.current_user.id === this.user.id) {
                    this.$store.dispatch('users/getCurrentUser', this.user.id)
                  }
                  this.closeDialog()
                } else {
                  this.showErrors(response.data.errors)
                }
              })
              .catch(response => this.showErrors(response.data.errors))
          } else {
            this.$store.dispatch('users/create', this.user)
              .then(response => {
                if (response.ok) {
                  this.saveLoading = false
                  this.$store.dispatch('users/organizationAdd', { organizationId: this.organization.id, userId: response.data.user.id })
                    .then(response => {
                      if (response.ok) {
                        this.$notify.success({
                          title: this.$t('messages.success'),
                          message: this.$t('global.type_is_created', { type: this.$tc('global.user') }),
                          position: 'bottom-right'
                        })
                      } else {
                        this.showErrors(response.data.errors)
                      }
                      this.closeDialog()
                    })
                    .catch(response => this.showErrors(response.data.errors))
                }
              })
              .catch(response => this.showErrors(response.data.errors))
          }
        }
      })
    },
    closeDialog () {
      this.$emit('close-dialog')
      this.$store.dispatch('organizations/all')
    },
    showErrors (errorsObject) {
      let errors = []
      for (let attribute in errorsObject) {
        if (errorsObject.hasOwnProperty(attribute)) {
          errors.push(this.first(errorsObject[attribute]))
        }
      }
      this.$notify.error({
        title: this.$t('messages.error'),
        message: errors.join(',') || this.$t('messages.generic_help_tooltip'),
        position: 'bottom-right'
      })
    }
  }
}
</script>

<style lang="scss">
.user-dialog {
  .el-dialog {
    width: 95%;

    @include media(">=desktop") {
      width: 45%;
    }

    @include media(">=tablet", "<desktop") {
      width: 65%;
    }

    .dialog-container {
      flex-wrap: wrap;

      .avatar-uploader {
        max-width: 256px;
        margin: 0 auto;

        @include media(">tablet") {
          padding: 40px;
        }
        .el-upload {
          width: 100%;
        }
        .el-upload-dragger {
          width: 100%;
          height: 100%;
        }
        .el-upload-dragger:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          color: #8c939d;
          width: 100%;
          line-height: 178px;
          text-align: center;
        }
        .avatar {
          width: 100%;
          display: block;
        }
      }

      .el-select {
        width: 100%;
      }

      .dialog-footer {
        width: 100%;

        @include media(">tablet") {
          margin: 0 10px;
        }

        .el-button {
          width: 100%;
          margin: 10px 0 0 0;

          @include media(">tablet") {
            width: auto;
            margin: initial;

            & + .el-button {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
