<template>
  <div class="doc-info">
    <div class="doc-info-container">
      <div class="info">
        <h3 class="header">{{$t('document.information')}}:</h3>
        <document-switch v-if="!document.archive_number && (this.userHasRights(this.current_user, 'writer') || this.current_user.name == document.user)" />
        <div class="label-value">
          <p>{{$t('document.created_by')}}</p>
          <p>{{document.user}}</p>
        </div>
        <div class="label-value">
          <p>{{$t('global.created_on')}}</p>
          <p>{{$d(new Date(document.created_at), 'long', locale)}}</p>
        </div>
        <div class="label-value">
          <p>{{$t('global.updated_on')}}</p>
          <p>{{$d(new Date(document.updated_at), 'long', locale)}}</p>
        </div>
        <div
          class="label-value"
          v-if="document.archive_number == null && (this.userHasRights(this.current_user, 'writer') || this.current_user.name == document.user)"
        >
          <p>{{$t('document.visible')}}</p>
          <p>
            <el-switch
              v-model="document.visible"
              @change="changeVisible"
            ></el-switch>
          </p>
        </div>
        <div v-if="this.userHasRights(this.current_user, 'reader') || this.current_user.name == document.user">
          <share-button :document="document" />
          <convert />
        </div>

        <div
          class="button-container"
          v-if="this.userHasRights(this.current_user, 'writer') || this.current_user.name == document.user"
        >
          <div
            class='save-fill'
            v-if="!edit"
          ></div>
          <save-button
            v-if="edit"
            @save-document="saveDocument"
          />
          <archive-button
            type="document"
            :document="document"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Convert from '../document/Convert'
import ArchiveButton from '../share/ArchiveButton'
import SaveButton from '../document/SaveButton'
import DocumentSwitch from '../document/DocumentSwitch'
import ShareButton from '../share/ShareButton'

import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'doc-info',
  mixins: [mixin],
  components: {
    Convert,
    ArchiveButton,
    SaveButton,
    DocumentSwitch,
    ShareButton
  },
  computed: mapState({
    edit: state => state.documents.edit,
    document: state => state.documents.document,
    current_user: state => state.users.current_user,
    locale: state => localStorage.getItem('locale')
  }),
  methods: {
    saveDocument () {
      this.$emit('save-document')
    },
    changeVisible: function () {
      this.$store.dispatch('documents/save', this.document)
        .then(response => {
          if (response.ok && !response.data.errors) {
            this.$notify({
              type: (this.document.visible ? 'success' : 'warning'),
              title: this.$t('messages.success'),
              message: this.$t('document.visible_change', { type: (this.document.visible ? this.$t('document.visible') : this.$t('document.invisable')) }),
              position: 'bottom-right'
            })
          } else {
            this.$notify.error({
              title: this.$t('messages.error'),
              message: this.$t('document.visible_cant_change_when_archived'),
              position: 'bottom-right'
            })
          }
        })
        .catch(_ => {
          this.$notify.error({
            title: this.$t('messages.error'),
            message: this.$t('messages.generic_help_tooltip'),
            position: 'bottom-right'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.doc-info {
  height: 100%;

  .doc-info-container {
    height: calc(100% - 40px);
    padding: 20px;
    border-left: 1px solid $template-color-background-hover;

    .info {
      height: 100%;
      display: flex;
      flex-direction: column;

      .document-switch {
        flex-grow: 0;
      }

      .header {
        color: #000;
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .label-value {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      p:first-child {
        font-weight: bold;
      }

      p {
        margin: 3px;
      }
    }

    .button-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.el-dialog__body {
  word-break: break-word;
}
</style>
