export default {
  data () {
    return {
      accessRoles: { 'reader': 1, 'writer': 2 },
      roles: { 'guest': 0, 'reader': 1, 'writer': 2, 'admin': 3, 'super_admin': 4 },
      translatedRoles: Object.freeze(
        {
          0: this.$t('global.role_guest'),
          1: this.$t('global.role_reader'),
          2: this.$t('global.role_writer'),
          3: this.$t('global.role_admin'),
          4: this.$t('global.role_super_admin')
        })
    }
  },
  methods: {
    /**
     * transform image url with the default ROOT and optional fallback image
     * @param {String} imageUrl
     * @param {String} fallBackImage
     * @returns {String} imageUrl or null
     */
    getImageOrigin (imageUrl, fallBackImage = null) {
      if (imageUrl !== null) {
        return process.env.VUE_APP_DEFAULT_ROOT + imageUrl
      }
      if (fallBackImage !== null) {
        return fallBackImage
      }
      return null
    },
    /**
     * returns the first word from a string
     * @param {String} string
     * @returns {String} firstWord
     */
    getFirstWord (string) {
      return string !== null ? string.replace(/ .*/, '') : null
    },
    /**
     * capitalizes the first letter of a string
     * @param {String} string
     * @returns {String} string
     */
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    /**
     * translate system role to language role
     * @param {Number} roleNumber
     * @returns {String} roleTranslation
     */
    translateRole (roleNumber) {
      return this.translatedRoles[roleNumber]
    },
    /**
     * checks if the given user has enough rights
     * @param {User} user
     * @param {String} role
     * @returns {Boolean} hasRights
     */
    userHasRights (user, role = 'guest') {
      return user.role >= this.roles[role]
    },
    /**
     * gets the first item from the array
     * @param {Array} array
     * @returns {Object|String} object|string
     */
    first (array) {
      if (!Array.isArray(array)) return null
      return array.find(e => true)
    },
    /**
     * gets the last item of an array
     * @param {Array} array
     * @returns {Object|String} object|string
     */
    last (array) {
      if (!Array.isArray(array)) return null
      return array.slice(-1)[0]
    },
    /**
     * regular expression to check whether the given input is and e-mailaddress
     * @param {String} email
     * @returns {Boolean} validEmail
     */
    validEmail (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    /**
     * shows a element notifiction with the given type message(s) and optionally function for callback on click
     * @param {String} type
     * @param {Array|String} messages
     * @param {Function} clickCallback default null
     */
    message (type, messages, clickCallback = null) {
      let title = this.$t('messages.' + type)
      if (messages instanceof Array) {
        messages.forEach(line => {
          this.$notify({
            title: title,
            type: type,
            message: line || this.$t('messages.generic_help_tooltip'),
            position: 'bottom-right',
            onClick: clickCallback
          })
        })
      } else {
        this.$notify({
          title: title,
          type: type,
          message: messages || this.$t('messages.generic_help_tooltip'),
          position: 'bottom-right',
          onClick: clickCallback
        })
      }
    },
    /**
     * Always shows the default contact Zisoo error message
     * @param {String|null} message
     */
    error (message = null) {
      this.$notify.error({
        title: this.$t('messages.error'),
        message: message || this.$t('messages.generic_help_tooltip'),
        position: 'bottom-right'
      })
    }
  }
}
