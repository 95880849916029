import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './styles/element-variables.scss'
import VueResource from 'vue-resource'
import Editor from '@tinymce/tinymce-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import 'element-ui/lib/theme-chalk/display.css'
import 'normalize.css'
import VueFlags from '@growthbunker/vueflags'
import i18n from './i18n'

import {
  faSignOutAlt,
  faSignInAlt,
  faFolder,
  faFolderOpen,
  faFileAlt,
  faAngleRight,
  faRecycle,
  faUndoAlt,
  faChevronRight,
  faBuilding,
  faSave,
  faPaperPlane,
  faEnvelope,
  faUser,
  faEyeSlash,
  faLock,
  faShareAlt,
  faLink,
  faQuestionCircle,
  faDownload,
  faUpload,
  faUnlock,
  faPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add({
  faSignOutAlt,
  faSignInAlt,
  faFolder,
  faFolderOpen,
  faFileAlt,
  faAngleRight,
  faRecycle,
  faUndoAlt,
  faChevronRight,
  faBuilding,
  faSave,
  faPaperPlane,
  faEnvelope,
  faUser,
  faEyeSlash,
  faLock,
  faShareAlt,
  faLink,
  faQuestionCircle,
  faDownload,
  faUpload,
  faUnlock,
  faPlus
})
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('tinymce-editor', Editor)
Vue.use(VueResource)
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(VueDOMPurifyHTML)
Vue.use(VueFlags, {
  iconPath: '/flags/'
})

Vue.config.productionTip = false
Vue.http.options.root = process.env.VUE_APP_DEFAULT_ROOT
Vue.http.headers.common['Accept'] = 'application/json'
Vue.http.headers.common['Access-Control-Allow-Headers'] = '*'

Vue.http.interceptors.push((request, next) => {
  if (localStorage.getItem('jwt')) {
    request.headers.set('Authorization', `Bearer ${localStorage.getItem('jwt')}`)
  }
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
