<template>
  <div class="folder-line">
    <folder-dialog
      @close-dialog="changeDialogState(false)"
      :show="showFolderDialog"
      :folder="folder"
    ></folder-dialog>
    <router-link :to="'/documents/' + ($route.params.slug ? $route.params.slug + '/': '') + folder.slug">
      <font-awesome-icon :icon="['fas', 'folder']" />
      {{folder.name}}
      <el-tooltip
        placement="top"
        :content="$t('document.archived_full', { prefix: $tc('document.prefix_the') ,type: $tc('global.folder') })"
      >
        <font-awesome-icon
          class="folder-icon lock"
          v-if="folder.archive_number"
          :icon="['fas', 'lock']"
        />
      </el-tooltip>
    </router-link>
    <el-button-group
      v-if="this.userHasRights(this.current_user, 'writer') || this.current_user.name == folder.user"
      class="document-buttons"
    >
      <el-button
        round
        size="medium"
        @click="changeDialogState(folder.id, true)"
      >Bewerk</el-button>
      <el-button
        round
        size="medium"
        type="danger"
        @click="delFolder(folder.id)"
      >Verwijder</el-button>
    </el-button-group>
  </div>
</template>

<script>
import mixin from '../../mixins'
import { mapState } from 'vuex'
import FolderDialog from './FolderDialog'

export default {
  name: 'folder-line',
  mixins: [mixin],
  components: {
    FolderDialog
  },
  props: {
    folder: Object
  },
  data () {
    return {
      showFolderDialog: false
    }
  },
  computed: mapState({
    current_user: state => state.users.current_user
  }),
  methods: {
    changeDialogState (folderId, state) {
      this.showFolderDialog = state
    },
    delFolder (id) {
      this.$confirm(this.$t('global.type_delete_question', { prefix: this.$t('document.prefix_the'), type: this.$tc('global.folder') }), this.$t('global.attention'), {
        confirmButtonText: this.$t('global.delete'),
        cancelButtonText: this.$t('global.cancel'),
        type: 'error',
        roundButton: true,
        center: true
      }).then(_ => {
        this.$store.dispatch('folders/delete', id).then(_ => {
          this.$emit('update')
          this.$notify.success({
            title: this.$t('messages.success'),
            message: this.$t('global.type_is_deleted', { type: this.$tc('global.folder') }),
            position: 'bottom-right'
          })
        }).catch(response => {
          this.$notify.error({
            title: this.$t('messages.error'),
            message: response.data.response ? response.data.response : this.$t('messages.generic_help_tooltip'),
            position: 'bottom-right'
          })
        })
      })
    }
  }
}
</script>

<style lang="scss">
.folder-line {
  .folder-buttons {
    margin-right: 5px;
  }

  svg {
    font-size: 1.4em;
  }

  .folder-icon {
    margin-left: 10px;
    font-size: 1.1em;

    &.lock {
      color: #ffa000;
    }
  }
}
</style>
