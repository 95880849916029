<template>
  <div id="app">
    <div class="container">
      <navigation></navigation>
      <div class="content">
        <component-header v-if="showHeader"></component-header>
        <router-view class="page"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import componentHeader from './components/navigation/Component-Header'
import navigation from './components/navigation/Navigation'
import mixin from './mixins'
import { mapState } from 'vuex'

export default {
  mixins: [mixin],
  components: {
    navigation,
    componentHeader
  },
  data () {
    return {
      router: this.$router
    }
  },
  computed: mapState({
    current_user: state => state.users.current_user,
    showHeader: function (object) {
      return this.isNotRouteAuthentication()
    }
  }),
  methods: {
    isNotRouteAuthentication: function () {
      let paths = ['authentication', 'authentication_validate', 'two_factor']
      return !paths.includes(this.$router.currentRoute.name)
    },
    /*
    * always access authentication view or if jwt and current_user isset
    */
    hasAccess: function (routeName) {
      // checks if user is logged in
      if (routeName === 'authentication' || routeName === 'two_factor' || routeName === 'authentication_validate' || (localStorage.getItem('jwt') && this.current_user && this.current_user.id)) {
        // checks if the users role has correct rights
        if (routeName === 'organizations' && !this.userHasRights(this.current_user, 'admin')) {
          return false
        }
        return true
      }
      return false
    },
    resetAccess: function () {
      this.$store.commit('reset')
      if (this.isNotRouteAuthentication()) { this.$router.push('/sign-in') }
    }
  },
  mounted () {
    // only try to sign in current_user if jwt isset
    if (localStorage.getItem('jwt')) {
      let data = JSON.parse(atob(localStorage.getItem('jwt').split('.')[1]))
      this.$store.dispatch('users/getCurrentUser', data.sub)
        .then(response => {
          if (response.ok && response.data.user) {
            this.message('success', this.$t('authentication.signed_in'))
            if (!this.isNotRouteAuthentication() || !this.hasAccess(this.$router.currentRoute.name)) {
              this.$router.push('/documents')
            }
          }
        })
        .catch(response => {
          if (!response.ok && this.isNotRouteAuthentication()) {
            this.$router.push('/sign-in')
          }
        })
    } else {
      if (this.isNotRouteAuthentication()) {
        this.$router.push('/sign-in')
      }
    }

    // checks on router change to see if user is logged in and has access
    this.$router.beforeEach((to, from, next) => {
      if (!this.hasAccess(to.name)) {
        this.resetAccess()
        next('/sign-in')
      } else {
        next()
      }
    })
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  background-color: $template-color-background;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  height: 100%;
}

// set tooltip link color (else it's not readable)
.el-tooltip__popper {
  div {
    a {
      color: $template-color-first;
    }
  }
}

.container {
  height: 100%;

  .navigation {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
  }

  .content {
    width: 100%;

    .page {
      /* Make the page look like it is in inset border shadow*/
      @include box-shadow(inset 0px 0px 20px 0px rgba(0, 0, 0, 0.25));

      /* needed else the whole page gets a scroll we don't want the header bar */
      overflow-y: scroll;
      width: 100%;

      /* component header is 80px with 2px border and navigation is 73px */
      height: calc(100vh - 82px - 73px);

      /* same but without navigation as that is on the side now   */
      @include media(">tablet") {
        height: calc(100vh - 82px);
      }
    }
  }
}

.page-card {
  @include media(">tablet") {
    background-color: $template-color-background;
    max-width: 1000px;
    margin: 0 auto 75px auto;
    padding: 30px;
    @include box-shadow(0 0 24px 0 rgba(0, 0, 0, 0.2));
    @include border-radius(15px);
  }
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.do-select {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

@include media(">=tablet") {
  .container {
    padding-left: 120px;

    .navigation {
      width: 120px;
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: initial;
      z-index: 10;
    }

    .content {
      .page {
        padding-bottom: 0;
      }
    }
  }
}
</style>
