<template>
  <div class="breadcrumbs">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(path, index) in paths"
        :key="index"
        :to="checkPath(path)"
      >{{capitalize(path.text)}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>

export default {
  name: 'breadcrumbs',
  computed: {
    paths: function () {
      if (!this.$route.params.slug) {
        return []
      }

      var allSlugs = []
      var totalSlug = ''
      var slugs = this.$route.params.slug.split('/')
      for (var slug in slugs) {
        totalSlug += (totalSlug !== '' ? '/' : '') + slugs[slug]
        allSlugs.push({ text: slugs[slug], url: totalSlug })
      }
      return allSlugs
    }
  },
  methods: {
    checkPath (path) {
      let currentPath = this.$router.currentRoute.path
      if (('/document/' + path.url) === currentPath ||
        ('/documents/' + path.url) === currentPath) {
        return false
      }
      return '/documents/' + path.url
    },
    capitalize ([firstLetter, ...rest]) {
      return [firstLetter.toLocaleUpperCase(), ...rest].join('')
    }
  }
}
</script>

<style lang="scss">
div .breadcrumbs {
  margin-bottom: 20px;
  padding: 0 6px;

  .el-breadcrumb__item {
    margin-bottom: 10px;
  }
}
</style>
