<template>
  <div class="create-buttons">
    <el-dropdown
      v-if="this.userHasRights(this.current_user, 'writer')"
      @command="create"
    >
      <el-button
        round
        type="primary"
        :disabled="!folder"
      >
        {{$t('global.create')}}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="folder">
          <font-awesome-icon
            :icon="['fa', 'folder']"
            style="margin-right: 15px; width: 15px;"
          />{{$tc('global.folder')}}
        </el-dropdown-item>
        <el-dropdown-item command="document">
          <font-awesome-icon
            :icon="['fa', 'file-alt']"
            style="margin-right: 15px; width: 15px;"
          />{{$tc('global.document')}}
        </el-dropdown-item>
        <el-dropdown-item command="upload">
          <font-awesome-icon
            :icon="['fa', 'upload']"
            style="margin-right: 15px; width: 15px;"
          />{{$tc('global.upload')}}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <upload-dialog
      :visible="showUploadDialog"
      @close="showUploadDialog = false"
    />
  </div>
</template>

<script>
import UploadDialog from './UploadDialog'

import { mapState } from 'vuex'
import mixin from './../../mixins'

export default {
  name: 'create-buttons',
  mixins: [mixin],
  components: {
    UploadDialog
  },
  data () {
    return {
      showUploadDialog: false
    }
  },
  computed: mapState({
    folder: state => state.folders.folder ? state.folders.folder : null,
    organization: state => state.organizations.organization,
    current_user: state => state.users.current_user
  }),
  methods: {
    create (command) {
      if (command === 'folder') {
        this.createFolder()
      } else if (command === 'document') {
        this.createDocument()
      } else if (command === 'upload') {
        this.showUploadDialog = true
      }
    },
    createFolder () {
      this.$prompt(this.$t('global.fill_in_name_of_new_type', { type: this.$tc('global.folder') }), this.$t('global.type_create', { type: this.$tc('global.folder') }), {
        confirmButtonText: this.$t('global.create'),
        cancelButtonText: this.$t('global.cancel'),
        inputPattern: /[A-z0-9()_\-=+!@#$%&*~€]/,
        inputErrorMessage: this.$t('global.fill_in_a_valid_type', { type: this.$t('user.name') })
      }).then((data) => {
        let request = this.$store.dispatch('folders/create', {
          name: data.value,
          folder_id: this.folder.id,
          organization_id: this.folder.organization_id,
          user_id: this.current_user.id
        })
        this.handleResponse(request, this.$tc('global.folder'))
      })
    },
    createDocument () {
      this.$prompt(this.$t('global.fill_in_name_of_new_type', { type: this.$tc('global.document') }), this.$t('global.type_create', { type: this.$tc('global.document') }), {
        confirmButtonText: this.$t('global.create'),
        cancelButtonText: this.$t('global.cancel'),
        inputPattern: /[A-z0-9()_\-=+!@#$%&*~€]/,
        inputErrorMessage: this.$t('global.fill_in_a_valid_type', { type: this.$t('user.name') })
      }).then((data) => {
        let request = this.$store.dispatch('documents/create', {
          title: data.value,
          folder_id: this.folder.id
        })
        this.handleResponse(request, this.$tc('global.document'))
      })
    },
    handleResponse (request, type) {
      request
        .then(response => {
          if (response.ok && !response.body.errors) {
            this.$emit('update')
            this.$notify.success({
              title: this.$t('messages.success'),
              message: this.$t('global.type_is_created', { type: type }),
              position: 'bottom-right'
            })
          } else {
            this.showErrors(response.body.errors)
          }
        })
        .catch(response => {
          this.showErrors(response.body.errors)
        })
    },
    showErrors (errorsObject) {
      let errors = []
      for (let attribute in errorsObject) {
        if (errorsObject.hasOwnProperty(attribute)) {
          errors.push(this.first(errorsObject[attribute]))
        }
      }
      this.$notify.error({
        title: this.$t('messages.error'),
        message: errors.join(',') || this.$t('messages.generic_help_tooltip'),
        position: 'bottom-right'
      })
    }
  }
}
</script>

<style lang="scss">
.create-buttons {
  text-align: center;

  @include media(">tablet") {
    text-align: right;
  }
}
</style>
