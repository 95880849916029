import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

const actions = {
  upload ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('convert', data)
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  download ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .get('convert/' + data.id + '/' + data.type, { responseType: 'arraybuffer' })
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

export default {
  namespaced: true,
  plugins: [VuexReset()],
  actions
}
