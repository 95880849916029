<template>
  <div class="search">
    <img
      class="search-icon"
      src="@/assets/images/search.png"
      alt="search"
    />
    <el-select
      v-model="search"
      :no-data-text="$t('header.no-data')"
      remote
      filterable
      reserve-keyword
      :placeholder="$t('header.placeholder')"
      :default-first-option="true"
      :remote-method="getSearchData"
      :loading="loading"
      @change="runSearch"
    >
      <el-option
        v-for="item in options"
        :key="item.label + '_' + item.value + '_' + item.type"
        :label="item.label"
        :value="item.value"
      >
        <font-awesome-icon
          class="font-awesome-icon"
          :icon="['fas', getIcon(item.type)]"
        />
        <span style="margin-left: 10px">{{ item.label }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '../../mixins'

export default {
  name: 'search',
  mixins: [mixin],
  computed: mapState({
    currentUser: state => state.users.current_user,
    documents: state => state.documents.all,
    folders: state => state.folders.all,
    organizations: state => state.organizations.all
  }),
  data () {
    return {
      loading: false,
      search: null,
      options: []
    }
  },
  methods: {
    getIcon (type) {
      switch (type) {
        case 'folder': return 'folder'
        case 'document': return 'file-alt'
        case 'organization': return 'building'
        case 'user': return 'user'
        default: return 'folder'
      }
    },
    getSearchData (query) {
      if (query !== '') {
        this.options = []
        this.loading = true
        setTimeout(() => {
          this.$store.dispatch('search/query', query)
            .then(response => {
              if (response.ok && response.data) {
                let data = response.data
                data.documents.forEach(item => {
                  this.options.push({ value: item.document.full_slug, label: item.document.title, type: 'document' })
                })
                data.folders.forEach(item => {
                  this.options.push({ value: item.folder.full_slug, label: item.folder.name, type: 'folder' })
                })
                data.organizations.forEach(item => {
                  this.options.push({ value: '/organizations/' + item.organization.name.toLowerCase(), label: item.organization.name, type: 'organization' })
                })
                data.users.forEach(item => {
                  let organization = this.firstOrganizationById(item.user.organizations)
                  if (organization) {
                    this.options.push({ value: '/organizations/' + organization.organization.name.toLowerCase(), label: item.user.name, type: 'user' })
                  }
                })
              }
              this.loading = false
            })
        }, 250)
      } else {
        this.options = []
      }
    },
    firstOrganizationById (array) {
      if (!Array.isArray(array)) {
        return null
      }
      return this.organizations.find(organization => array.includes(organization.organization.id))
    },
    runSearch (value) {
      if (this.$router.currentRoute.fullPath !== value) {
        this.$router.push(value)
      }
    }
  },
  mounted () {
    this.$store.dispatch('organizations/all')
  }
}
</script>

<style lang="scss">
.search {
  height: 100%;
  flex-grow: 1;
  display: flex;
  color: $template-color-text-light;
  @include transition(all 0.3s ease);

  .search-icon {
    width: 24px;
    height: 24px;
    margin: 9px 0 0 26px;
  }

  .el-select {
    width: 100%;
    input {
      border: none;
      padding: 0 0 0 30px;
      font-size: 1.25em;
    }
  }
}
</style>
