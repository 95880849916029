<template>
  <div class="forgot-password">
    <div class="auth-container">
      <h1>{{$t('authentication.forgot_password')}}</h1>
      <el-form
        ref="forgotten"
        :model="forgotten"
        :rules="rules"
        label-position="top"
        @submit.prevent="submitReset"
      >
        <el-form-item
          :label="$t('user.email')"
          prop="email"
        >
          <el-input
            name="email"
            type="email"
            :placeholder="$t('global.fill_in_your_type', { type: $t('user.email')})"
            v-model="forgotten.email"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="btn-submit"
            @click.prevent="submitReset"
            native-type="submit"
            type="primary"
            round
          >
            <font-awesome-icon :icon="['fas', 'envelope']" /> {{$t('global.reset')}} {{$t('user.password')}}
          </el-button>
        </el-form-item>
      </el-form>
      <div class="link-container">
        <router-link
          to="/sign-in"
          class="link"
        >{{$t('authentication.already_got_account')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'forgot-password',
  data () {
    return {
      forgotten: { email: null },
      rules: {
        email: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.email') }) },
          { type: 'email', message: this.$t('global.fill_in_a_valid_type', { type: this.$t('user.email') }) }
        ]
      }
    }
  },
  methods: {
    submitReset () {
      this.$refs['forgotten'].validate((valid) => {
        if (valid) {
          this.$store.dispatch('users/resetPassword', this.forgotten)
            .then(response => {
              if (response.ok) {
                this.$notify.success({
                  title: this.$t('messages.success'),
                  message: this.$t('authentication.password_reset'),
                  position: 'bottom-right'
                })
              } else {
                this.error()
              }
            })
            .catch(_ => this.error())
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.forgot-password {
  h1 {
    text-align: center;
  }

  .el-button.btn-submit {
    margin-top: 25px;
  }
}
</style>
