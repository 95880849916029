<template>
  <div class="navigation">
    <router-link
      class="zisoo-docs-icon"
      :to="user ? '/documents' : '/'"
    >
      <img
        src="@/assets/images/zisoo-docs.png"
        alt="Zisoo | Docs"
      />
      <p><span class="blue">Zisoo</span> | Docs</p>
    </router-link>
    <nav>
      <ul>
        <router-link
          :class="this.$router.currentRoute.name == 'document_slug' ? 'router-link-active' : ''"
          v-if="userLoggedIn()"
          to="/documents"
        >
          <li>
            <img
              src="@/assets/images/folder.png"
              alt="Documenten"
            />
            <p>{{ $tc('global.document', 2)}}</p>
          </li>
        </router-link>
        <router-link
          v-if="userLoggedIn() && userHasRights(this.user, 'admin')"
          to="/organizations"
        >
          <li>
            <img
              src="@/assets/images/organization.png"
              alt="Organization"
            />
            <p>{{ $tc('global.organization', 2)}}</p>
          </li>
        </router-link>
        <router-link
          v-if="userLoggedIn()"
          to="/user"
        >
          <li>
            <img
              src="@/assets/images/gear.png"
              alt="Zisoo | Docs"
            />
            <p>{{ $t('global.settings')}}</p>
          </li>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'navigation',
  mixins: [mixin],
  computed: mapState({
    user: state => state.users.current_user
  }),
  methods: {
    userLoggedIn: function () {
      if (this.user && this.user.id != null) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.navigation {
  background-color: $template-color-background;

  @include media(">tablet") {
    border-right: 2px solid $template-color-background-dark;
  }

  .zisoo-docs-icon {
    display: none;

    @include media(">tablet") {
      display: block;
      text-decoration: none;
      padding: 20px 0;
      color: $template-color-text;
      text-align: center;

      .blue {
        color: $template-color-first;
      }

      img {
        max-width: 26px;
        margin-bottom: 5px;

        @include media(">=tablet") {
          max-width: 45px;
          margin-bottom: 10px;
        }
      }

      p {
        margin: 0px;
      }
    }
  }

  nav {
    height: 100%;

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      @include media(">=tablet") {
        display: initial;
      }

      a {
        flex-grow: 1;
        display: block;
        text-decoration: none;
        color: $template-color-text;

        p {
          margin: 0px;
        }

        &.router-link-active {
          background-color: $template-color-first;
          & p {
            color: $template-color-background;
          }
        }

        &:hover:not(.router-link-active) {
          background-color: $template-color-background-hover;
        }

        li {
          padding: 10px;
          text-align: center;
          @include transition(all 0.3s ease);

          @include media(">=tablet") {
            padding: 20px 0;
          }

          img {
            max-width: 26px;
            margin-bottom: 5px;

            @include media(">=tablet") {
              max-width: 45px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
