<template>
  <div class="documents">
    <el-row type="flex">
      <el-col
        v-if="folders.length > 1"
        :xs="24"
        :sm="7"
        :lg="4"
      >
        <tree></tree>
      </el-col>
      <el-col
        class="grow"
        :xs="24"
        :sm="17"
        :lg="20"
      >
        <div
          v-loading="folderLoading"
          class="document-view"
        >
          <el-row
            class="breadcrumbs-flex"
            type="flex"
          >
            <el-col
              :sm="14"
              :lg="12"
            >
              <breadcrumbs></breadcrumbs>
            </el-col>
            <el-col
              :sm="10"
              :lg="12"
            >
              <create-buttons @update="update" />
            </el-col>
          </el-row>
          <p class="document-name">
            <span>{{$tc('global.folder', 2)}}</span>
          </p>
          <div v-if="Array.isArray(subFolders) && subFolders.length">
            <folder-line
              v-for="folder in subFolders"
              :key="folder.folder.id"
              :folder="folder.folder"
              @update="update"
            ></folder-line>
          </div>
          <div v-else>
            <p class="empty-text">{{$t('documents.not_added_yet', { 'type': $tc('global.folder', 2).toLowerCase() })}}</p>
          </div>
          <p class="folder-name">
            <span>{{$tc('global.document', 2)}}</span>
          </p>
          <div v-if="Array.isArray(documents) && documents.length">
            <document-line
              v-for="document in documents"
              :key="document.document.id"
              :document="document.document"
              @update="update"
            ></document-line>
          </div>
          <div v-else>
            <p class="empty-text">{{$t('documents.not_added_yet', { 'type': $tc('global.document', 2).toLowerCase() })}}</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import tree from '../components/navigation/Tree'
import breadcrumbs from '../components/documents/BreadCrumbs'
import folderLine from '../components/documents/FolderLine'
import documentLine from '../components/documents/DocumentLine'
import createButtons from '../components/documents/CreateButtons'
import mixin from '../mixins'
import { mapState } from 'vuex'

export default {
  name: 'documents',
  mixins: [mixin],
  components: {
    tree,
    breadcrumbs,
    folderLine,
    documentLine,
    createButtons
  },
  data () {
    return {
      folderLoading: false
    }
  },
  computed: mapState({
    documents: state => {
      if (state.documents.all == null) {
        return null
      }
      return state.documents.all.filter(document => {
        if (!document.document || !state.folders.folder) {
          return false
        }
        return document.document.folder_id === state.folders.folder.id
      })
    },
    folder: state => state.folders.folder ? state.folders.folder : null,
    folders: state => state.folders.all ? state.folders.all : null,
    subFolders (state) {
      if (state.folders.folder && state.folders.folder.id) {
        return state.folders.folder ? state.folders.folder.folders : null
      }
      return null
    },
    organization: state => state.organizations.organization,
    currentUser: state => state.users.current_user
  }),
  methods: {
    getRightFolders () {
      if (this.folder.id && this.folder.folders) { this.subFolders = this.folder.folders }
      if (this.$router.currentRoute.path === '/documents') {
        this.subFolders = this.folders
      }
    },
    update () {
      if (this.$route.params.slug) {
        let parentSlug = this.$route.params.slug.split('/').slice(-2)[0]
        let lastSlug = this.last(this.$route.params.slug.split('/'))
        let finalSlug = parentSlug === lastSlug ? null : parentSlug
        this.$store.dispatch('folders/slug', { slug: lastSlug, parent_slug: finalSlug })
          .then(_ => { this.folderLoading = false })
      } else {
        // if no slug is detected also check if there is only one root folder to switch to
        if (this.folders.length === 1 && !this.first(this.folders).folder.has_parent) {
          this.switchToFolder(this.first(this.folders))
          this.folderLoading = false
        }
        this.$store.commit('folders/setFolder', null)
      }
    },
    switchToFolder (folder) {
      if (this.$router.currentRoute.path !== ('/documents/' + folder.folder.slug)) {
        this.$router.push('/documents/' + folder.folder.slug)
      }
    }
  },
  mounted () {
    if (this.$route.params.slug) {
      this.folderLoading = true
    }
    this.$store.dispatch('folders/all')
      .then(_ => this.$store.dispatch('documents/all')
        .then(_ =>
          this.update()
        ))
  },
  watch: {
    $route (to, from) {
      this.folderLoading = true
      this.update()
    }
  }
}
</script>

<style lang="scss">
.documents {
  height: 100%;
  width: 100%;
  background-color: $template-color-background;

  .el-row {
    @include media(">tablet") {
      height: 100%;
    }
    flex-flow: wrap;
  }

  .grow {
    flex-grow: 1;
  }

  .document-view {
    padding: 25px;

    .breadcrumbs-flex {
      justify-content: space-between;
    }

    .folder-name,
    .document-name {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.4em;
      padding: 5px;
      color: lighten($template-color-text-light, 35%);
    }
    .folder-line,
    .document-line {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      &:hover {
        background-color: darken($template-color-background-dark, 5%);
      }

      a,
      .link {
        flex-grow: 1;
        padding: 15px 5px;
        color: $template-color-text;
        text-decoration: none;
        @include transition(all 0.3s ease);

        &:hover {
          cursor: pointer;
        }
      }
    }
    p.empty-text {
      margin: 15px;
      color: $template-color-text-light;
    }
  }
}
</style>
