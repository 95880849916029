<template>
  <div class="share-dialog">
    <el-dialog
      :title="$t('share.dialog_name')"
      :visible.sync="show"
      :beforeClose="beforeClose"
      append-to-body
    >
      <p>{{$t('share.person_organization_or_email')}}</p>
      <share-able
        :type="type"
        :document="document"
        :folder="folder"
      />
      <p>{{$t('global.rights')}}
        <el-tooltip
          placement="top"
          :content="$t('share.rights_tooltip')"
        >
          <font-awesome-icon :icon="['fas', 'question-circle']" />
        </el-tooltip>
      </p>
      <el-select
        name="role"
        v-model="rights"
        :placeholder="$t('user.role')"
      >
        <el-option
          v-for="role in accessRoles"
          :key="role"
          :label="translateRole(role)"
          :value="role"
        >
        </el-option>
      </el-select>
      <p>{{$t('global.access_till')}}:</p>
      <el-date-picker
        v-model="validUntil"
        type="datetime"
        format="dd-MM-yyyy - hh:mm:ss"
        :placeholder="$t('global.select_time_and_or_date')"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <p
        v-if="linkOutput"
        class="link-ouput"
      >{{linkOutput}}</p>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="createShareLink"
        >
          <font-awesome-icon :icon="['fas', 'link']" />
          {{$t('share.create_share_link')}}
        </el-button>
        <el-button
          type="primary"
          @click="beforeClose"
        >{{$t('global.close')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ShareAble from './ShareAble'
import mixin from '../../mixins'

export default {
  name: 'share-dialog',
  mixins: [mixin],
  components: {
    ShareAble
  },
  props: {
    show: Boolean,
    type: String,
    folder: Object,
    document: Object
  },
  data () {
    return {
      validUntil: null,
      rights: null,
      linkOutput: null,
      pickerOptions: {
        shortcuts: [{
          text: this.$t('global.day'),
          onClick (picker) {
            const date = new Date()
            date.setTime(date.setDate(date.getDate() + 1))
            picker.$emit('pick', date)
          }
        }, {
          text: this.$t('global.week'),
          onClick (picker) {
            const date = new Date()
            date.setTime(date.setDate(date.getDate() + 7))
            picker.$emit('pick', date)
          }
        }, {
          text: this.$t('global.year'),
          onClick (picker) {
            const date = new Date()
            date.setTime(date.setMonth(date.getMonth() + 1))
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },
  methods: {
    createShareLink () {
      this.$store.dispatch('access/create')
        .then(response => {
          this.linkOutput = response.data.link
        })
    },
    beforeClose () {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style lang="scss">
.options-dialog {
  width: 90%;

  h2 {
    font-weight: bold;
  }

  p {
    font-weight: bold;
  }

  .link-ouput {
    padding: 25px;
    color: #fff;
    background-color: rgb(14, 183, 5);
  }
}
</style>
