<template>
  <div class="tree">
    <div
      v-for="folder in folders"
      :key="folder.id"
    >
      <div
        v-if="folder.folder_id == null"
        class="folder"
      >
        <router-link :to="'/documents/' + folder.folder.slug">
          <font-awesome-icon :icon="['fas', 'building']" />{{folder.folder.name}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'tree',
  computed: mapState({
    folders: state => state.folders.all
  }),
  mounted () {
    this.$store.dispatch('folders/all')
  }
}
</script>

<style lang="scss">
.tree {
  @include media(">tablet") {
    height: 100%;
  }
  background-color: $template-color-background-dark;

  .folder {
    @include transition(all 0.3s ease);

    a {
      display: block;
      padding: 15px 25px;
      color: $template-color-text;
      text-decoration: none;
      font-weight: bold;

      svg {
        font-size: 1em;
        vertical-align: sub;
        margin-right: 10px;
      }
    }

    &:hover {
      background-color: darken($template-color-background-dark, 5%);
    }
  }
}
</style>
