<template>
  <div class="tinymce-edit">
    <tinymce-editor
      id="tiny-mce-editor"
      :api-key="this.tinyKey"
      v-model="document.contents"
      :init="{
        plugins: ['lists','wordcount', 'autoresize', 'autolink', 'code', 'fullscreen', 'image', 'link', 'table', 'toc'],
        external_plugins: {'wave': 'https://cdn2.codox.io/waveTinymce/plugin.min.js'},
        wave: codexConfig,
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
        images_upload_url: uploadUrl,
        imagetools_cors_hosts: ['localhost:8080', 'localhost:3000'],
        mobile: {
          theme: 'mobile',
          plugins: ['lists', 'autolink'],
          toolbar: ['undo', 'bold', 'italic', 'styleselect']
        },
        font_formats: 'Roboto=Roboto-Regular, Helvetica, sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
        image_title: true,
        image_list: this.images,
        images_upload_handler: this.uploadImage,
        min_height: 500,
        setup: setupDefaults
      }"
      @onChange="save(6000)"
    ></tinymce-editor>
  </div>
</template>

<script>
import mixin from '../../mixins'

export default {
  name: 'tinymce-edit',
  mixins: [mixin],
  props: {
    document: Object,
    user: Object
  },
  computed: {
    codexConfig: function () {
      return {
        docId: this.document.id,
        user: {
          id: this.user.id,
          name: this.user.name,
          avatar: this.getImageOrigin(this.user.avatar, require('../../assets/images/user-icon.png'))
        },
        apiKey: this.codexKey
      }
    },
    images: function () {
      if (this.document.images) {
        return this.document.images.map(image => {
          return {
            title: image.filename,
            value: this.defaultRoot + '/' + image.image_url
          }
        })
      }
      return null
    },
    tinyKey: function () {
      return process.env.VUE_APP_TINY_API_KEY
    },
    defaultRoot: function () {
      return process.env.VUE_APP_DEFAULT_ROOT
    },
    codexKey: function () {
      return process.env.VUE_APP_CODEX_WAVE_API_KEY
    },
    uploadUrl: function () {
      return (
        this.defaultRoot + '/documents/' + this.document.id + '/images'
      )
    },
    setupDefaults: function () {
      return function (editor) {
        editor.on('init', function () {
          this.execCommand('FontName', false, 'Roboto-Regular, Helvetica, sans-serif')
          this.execCommand('FontSize', false, '16px')
          this.execCommand('ForeColor', false, '#294c64')
        })
      }
    }
  },
  methods: {
    save (time = 30000) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.timer = null
        this.$emit('save-document')
      }, time)
    },
    uploadImage (blobInfo, success, failure) {
      let formData = new FormData()
      formData.append('jwt', localStorage.getItem('jwt'))
      formData.append('file', blobInfo.blob(), blobInfo.filename())
      this.$http
        .post('documents/' + this.document.id + '/images', formData)
        .then(response => success(this.defaultRoot + response.data.url))
        .catch(error => failure('HTTP Error: ' + error.status))
    }
  }
}
</script>

<style lang="scss">
// TODO fix api key of TinyMCE, always gives warning of wrong environment
.tox-notification.tox-notification--in.tox-notification--warning {
  display: none;
}

.tinymce-edit {
  .codox-container {
    margin-top: 12px;
  }
}
</style>
