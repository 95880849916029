<template>
  <div class='two-factor'>
    <div class='auth-container'>
      <h1>{{ $t('authentication.two_factor_authentication') }}</h1>
      <div
        v-show='showQR || qrLoading'
        class='qr-code'
        v-loading="qrLoading"
      >
        <img
          ref='qrImage'
          alt='QR Code image'
        />
      </div>
      <el-form
        ref='signInSecond'
        :model='signInSecond'
        :rules='rules'
        label-position='top'
        @submit.prevent='submitForm'
      >
        <el-form-item prop='token'>
          <div
            class='token-label'
            slot='label'
          >
            {{ $t('user.token') }}
            <two-factor-tooltip />
          </div>
          <el-input
            name='token'
            :placeholder="$t('global.fill_in_your_type', { type: $t('user.token') })"
            v-model="signInSecond.token"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class='btn-submit'
            @click.prevent='submitForm'
            native-type='submit'
            type='primary'
            round
          >
            <font-awesome-icon :icon="['fas', 'unlock']" />
            {{ $t('global.sign_in') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins'
import twoFactorTooltip from './TwoFactorTooltip'
import { mapState } from 'vuex'

export default {
  name: 'two-factor',
  mixins: [mixin],
  components: {
    twoFactorTooltip
  },
  data () {
    return {
      rules: {
        token: [
          {
            required: true,
            message: this.$t('global.fill_in_your_type', {
              type: this.$t('user.token')
            })
          }
        ]
      },
      showQR: false,
      qrLoading: false,
      enabled: this.$route.params.enabled,
      signInSecond: { email: null, password: null, token: null }
    }
  },
  computed: mapState({
    current_user: state => state.users.current_user
  }),
  methods: {
    submitForm () {
      this.$refs['signInSecond'].validate(valid => {
        this.current_user.token = this.signInSecond.token
        this.$store
          .dispatch('users/signInUser', this.current_user)
          .then(response => {
            if (response.ok && response.data.user) {
              this.$notify.success({
                title: this.$t('messages.success'),
                message: this.$t('authentication.logged_in'),
                position: 'bottom-right'
              })
              this.$router.push('/documents')
            } else {
              this.message('error', response.data.errors)
            }
          })
      })
    },
    generateTwoFactorQR () {
      this.$store.dispatch('auth/generate', { email: this.current_user.email })
        .then(response => {
          if (response.ok) {
            let blob = new Blob([response.data], { type: 'image/svg+xml' })
            let url = URL.createObjectURL(blob)
            this.$refs.qrImage.src = url
            this.showQR = true
            this.$refs.qrImage.addEventListener('load', () => URL.revokeObjectURL(url), { once: true })
            this.qrLoading = false
          } else {
            this.error()
            this.qrLoading = false
          }
        })
        .catch(_ => {
          this.error()
          this.qrLoading = false
        })
    }
  },
  mounted () {
    // check if the current user isset, else redirect to sign-in page
    if (this.current_user) {
      this.$store.dispatch('auth/check', this.current_user).then(response => {
        if (response.ok) {
          if (response.body.secret_set && !response.body.enabled) {
            this.qrLoading = true
            this.generateTwoFactorQR()
          }
        }
      })
    } else {
      this.$router.push('/sign-in')
    }
  }
}
</script>

<style lang="scss">
.auth-container {
  .qr-code {
    img {
      width: 200px;
      display: block;
      margin: 25px auto;
      box-sizing: border-box;
      image-rendering: crisp-edges;
    }
  }
  .token-label {
    display: inline;
  }
}
</style>
