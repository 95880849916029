<template>
  <div class="share-button">
    <el-button
      @click="dialogState(true)"
      type="primary"
      round
    >
      <font-awesome-icon :icon="['fas', 'share-alt']" /> {{$t('global.share')}}
    </el-button>
    <share-dialog
      :show="show"
      :document="document"
      :folder="folder"
      :type="type"
      @close-dialog="dialogState(false)"
    ></share-dialog>
  </div>
</template>

<script>
import ShareDialog from './ShareDialog'
import { mapState } from 'vuex'

export default {
  name: 'share-button',
  props: {
    document: Object,
    folder: Object
  },
  components: {
    ShareDialog
  },
  computed: mapState({
    type: function (state) {
      return this.document ? 'document' : 'folder'
    }
  }),
  data () {
    return {
      show: false
    }
  },
  methods: {
    dialogState (state) {
      ('emit received')
      this.show = state
    }
  }
}
</script>

<style lang="scss">
.share-button {
  .el-button {
    width: 100%;
  }
}
</style>
