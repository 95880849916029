<template>
  <el-dialog
    class="upload-dialog"
    :title="$t('global.upload')"
    :visible.sync="visible"
    :before-close="closeDialog"
    width="50%"
  >
    <el-form
      ref="documentForm"
      :model="document"
      :rules="rules"
      enctype="multipart/form-data"
      autocomplete="false"
      label-position="left"
      @submit.prevent="upload"
    >
      <el-form-item
        :label="$t('global.title')"
        prop="title"
      >
        <el-input
          name="title"
          type="text"
          v-model="document.title"
          :placeholder="$t('global.fill_in_your_type', { type: $t('global.title')})"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="upload-label"
        :label="$tc('global.document')"
      >
        <el-upload
          class="upload-document"
          drag
          action="#"
          :limit="1"
          :before-upload="setFile"
          :on-exceed="handleExceed"
          :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        </el-upload>
      </el-form-item>
      <slot name="footer">
        <div class="dialog-footer">
          <el-button @click="closeDialog()">{{$t('global.cancel')}}</el-button>
          <el-button
            @click.prevent="upload"
            native-type="submit"
            type="primary"
          >{{$t('global.save')}}</el-button>
        </div>
      </slot>
    </el-form>
  </el-dialog>
</template>

<script>
import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'upload-diaglog',
  mixins: [mixin],
  props: {
    visible: Boolean
  },
  data () {
    return {
      document: { title: null },
      fileList: [],
      file: null,
      rules: {
        title: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('global.title') }) }
        ],
        document: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$tc('global.document') }) }
        ]
      }
    }
  },
  computed: mapState({
    current_user: state => state.users.current_user,
    folder: state => state.folders.folder
  }),
  methods: {
    setFile (file) {
      this.file = file
      return false
    },
    upload () {
      this.$refs['documentForm'].validate((valid) => {
        if (valid) {
          let data = new FormData()
          data.append('title', this.document.title)
          data.append('file', this.file)
          data.append('folder_id', this.folder.id)
          this.$store.dispatch('convert/upload', data)
            .then(response => {
              this.$store.dispatch('documents/all')
                .then(_ => {
                  this.closeDialog()
                  this.message('success', this.$t('documents.document_uploaded'))
                })
            })
            .catch(response => this.message('error', response.data.errors))
        }
      })
    },
    handleExceed () {
      this.message('info', this.$t('documents.limit_exeeds_tooltip'))
    },
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss'>
.upload-dialog {
  text-align: initial;

  .upload-label label {
    width: 100%;
  }

  .upload-document {
    .el-upload {
      display: block;
    }
    .el-upload-dragger {
      display: block;
      width: 100%;
    }
  }
}
</style>
