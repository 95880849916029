<template>
  <div class="share-able">
    <div class="tag-box">
      <el-select
        v-model="tags"
        multiple
        filterable
        remote
        reserve-keyword
        :allow-create="true"
        :default-first-option="true"
        :placeholder="$t('global.share_search_placeholder')"
        :loading-text="$t('global.share_searching') + '...'"
        :no-data-text="$t('global.share_nothing_found')"
        :remote-method="searchTags"
        :loading="tagsLoading"
        @change="changeTags"
      >
        <el-option
          v-for="tag in availibleOptions"
          :key="tag.value"
          :label="tag.label"
          :value="tag.value"
        >
          <font-awesome-icon
            class="font-awesome-icon"
            :icon="['fas', (tag.type == 'user' ? 'user' : 'building')]"
          />
          <span style="margin-left: 10px">{{ tag.label }}</span>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'share-able',
  mixins: [mixin],
  props: {
    type: String,
    folder: Object,
    document: Object
  },
  data () {
    return {
      previousTagsState: [],
      tagsLoading: false,
      availibleOptions: [],
      tags: [],
      email: null
    }
  },
  computed: mapState({
    current_user: state => state.users.current_user,
    users: state => state.users.all,
    organizations: state => state.organizations.all,
    allShareAble: function (state) {
      let allShareAble = this.mapShareAble(this.organizations, 'organization')
      allShareAble = allShareAble.concat(this.mapShareAble(this.users, 'user'))
      return allShareAble
    },
    prefix: function (state) {
      return (this.type === 'document' ? this.$t('document.prefix_it') : this.$t('document.prefix_the'))
    }
  }),
  methods: {
    // used for finding an access by name (could be organization name or user name)
    findActualAccess (name) {
      let shareAbles = null
      this.allShareAble.forEach(element => {
        if (element.value === name) {
          shareAbles = element
        }
      })
      return shareAbles
    },
    // search in all defined availibleOptions (shareAbles)
    searchFor (name) {
      return this.allShareAble.filter(item => {
        return item.value.toLowerCase().includes(name.toLowerCase())
      })
    },
    // is used for checking if the found user is equal to the current user
    checkNotCurrentUser (user) {
      return user.user.name !== this.current_user.name
    },
    // maps the sharables for viewing in the select dropdown
    mapShareAble (array, name) {
      if (name === 'user') {
        array = array.filter(this.checkNotCurrentUser)
      }
      return array.map(item => {
        return { 'value': item[name].name, 'label': item[name].name, 'type': name, 'id': item[name].id }
      })
    },
    // reaches the backend and asks for users and organizations
    searchTags (query) {
      if (query !== '') {
        this.tagsLoading = true
        setTimeout(() => {
          this.tagsLoading = false
          this.availibleOptions = this.searchFor(query)
        }, 200)
      } else {
        this.availibleOptions = []
      }
    },
    // on page reload of other document or folder get info again
    loadTags () {
      let data = { 'type': this.type }
      if (this.type === 'document') {
        data['id'] = this.document.id
      } else if (this.type === 'folder') {
        data['id'] = this.document != null ? this.document.folder_id : this.folder.id
      }
      this.tags = []
      this.$store.dispatch('accesses/all', data)
        .then(response => {
          if (response.ok && response.data.accesses) {
            this.tags = this.tags.concat(response.data.accesses.map(access => {
              // for a found user
              if (access.access.user_id) {
                let user = this.users.find(user => user.user.id === access.access.user_id)
                return user.user.name
              }
              // for a found organization
              if (access.access.organization_id) {
                let organization = this.organizations.find(organization => organization.organization.id === access.access.organization_id)
                return organization.organization.name
              }
              // for a found emailaddress
              if (access.access.email) {
                return access.access.email
              }
            }))
          } else {
            this.showError(response.data.errors)
          }
        }).catch(response => {
          this.showError(response.data.errors)
        })
    },
    // add a tag to the current type (document or folder)
    changeTags (allTags) {
      // check if a tag has been removed
      if (allTags.length < this.previousTagsState.length) {
        this.removeTag(this.last(this.previousTagsState.filter(x => !allTags.includes(x))))
        return false
      }

      // check if nothing has changed
      if (allTags.length === this.previousTagsState.length) {
        return false
      }

      // when adding an e-mail open option dialog
      if (this.validEmail(this.last(allTags))) {
        this.email = this.last(allTags)
      } else {
        // when a normal user or organization has been found
        this.createAccess({ 'tagValue': this.last(allTags) })
      }
    },

    // actual access creation backend call
    createAccess (options) {
      let sharAble = this.findActualAccess(options.tagValue)
      if (sharAble || this.validEmail(options.tagValue)) {
        let data = { 'type': sharAble != null ? sharAble.type : 'email' }
        if (options.validUntil) {
          data['valid_until'] = options.validUntil
        }
        if (sharAble != null) {
          if (sharAble.type === 'user') {
            data['user_id'] = sharAble.id
          }
          if (sharAble.type === 'organization') {
            data['organization_id'] = sharAble.id
          }
        }
        if (this.validEmail(options.tagValue)) {
          data['email'] = options.tagValue
        }
        if (this.type === 'document') {
          data['document_id'] = this.document.id
        }
        if (this.type === 'folder') {
          data['folder_id'] = this.folder.id
        }
        this.$store.dispatch('accesses/create', data)
          .then(response => {
            if (response.ok && !response.data.errors) {
              this.$notify.success({
                title: this.$t('messages.success'),
                message: this.$t('global.has_access',
                  {
                    prefix: this.prefix,
                    type: this.type,
                    entity: sharAble.type
                  }),
                position: 'bottom-right'
              })
            } else {
              this.showError(response.data.errors)
            }
          })
          .catch(_ => this.error())
      }
    },

    // remove a tag from the current type (document or folder)
    removeTag (tagValue) {
      let sharAble = this.findActualAccess(tagValue)
      if (sharAble != null || this.validEmail(tagValue)) {
        let data = {
          'type': this.type,
          'id': this.document != null ? this.document.id : this.folder.id,
          'sort': sharAble ? sharAble.type : 'email',
          'sort_id': sharAble != null ? sharAble.id : tagValue
        }
        this.$store.dispatch('accesses/delete', data)
          .then(response => {
            if (response.ok && !response.data.errors) {
              this.$notify.success({
                title: this.$t('messages.success'),
                message: this.$t('global.has_no_access',
                  {
                    prefix: this.prefix,
                    type: this.type,
                    entity: sharAble.type
                  }),
                position: 'bottom-right'
              })
            } else {
              this.tags.push(tagValue)
              this.showError(response.data.errors)
            }
          })
          .catch(_ => {
            this.tags.push(tagValue)
            this.error()
          })
      }
    },
    // show notification error with specific error text when needed
    showError (errorText = null) {
      this.$notify.error({
        title: this.$t('messages.error'),
        message: errorText != null ? errorText : this.$t('messages.generic_help_tooltip'),
        position: 'bottom-right'
      })
    }
  },
  mounted () {
    this.$store.dispatch('organizations/all').then(_ => {
      this.$store.dispatch('users/all').then(_ => this.loadTags())
    })
  },
  watch: {
    // used for checking if the tags got updated
    tags: function () {
      this.previousTagsState = this.tags
    },
    // when the document or folder is set, refresh tags
    document: function () {
      this.loadTags()
    },
    folder: function () {
      this.loadTags()
    }
  }
}
</script>

<style lang="scss">
.share-able {
  width: 100%;

  .tag-box {
    .el-select {
      width: 100%;
      border: none;
    }

    .el-tag,
    .tag-add-btn {
      margin: 5px;
    }
  }
}
</style>
