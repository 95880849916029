<template>
  <div class="archive-button">
    <el-tooltip
      placement="top"
      :content="content"
    >
      <el-button
        v-if="
          this.userHasRights(this.current_user, 'writer') ||
          (document != null && this.current_user.name == document.user) ||
          (folder != null && this.current_user.name == folder.user)
        "
        type="danger"
        round
        plain
        @click="archive(archived)"
      >
        <font-awesome-icon :icon="['fas', (archived ? 'undo-alt' : 'recycle')]" />
        <span class="text">{{archived ? this.$t('document.set_back') : this.$t('document.archive')}}</span>
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '../../mixins'

export default {
  name: 'archive-button',
  mixins: [mixin],
  props: {
    type: String,
    document: Object,
    folder: Object
  },
  computed: mapState({
    prefix: function (state) {
      return (this.type === 'document' ? this.$t('document.prefix_it') : this.$t('document.prefix_the'))
    },
    content: function (state) {
      return this.$t('document.archive_tooltip', { prefix: this.prefix, type: this.type })
    },
    archived: function (state) {
      return this.type === 'document' ? this.document.archive_number != null : this.folder.archive_number != null
    },
    current_user: state => state.users.current_user
  }),
  methods: {
    archive (archived) {
      let unarchive = this.$t('document.unarchive_text', { prefix: this.prefix, type: this.type })
      let archive = this.$t('document.archive_text', { prefix: this.prefix, type: this.type })
      let confirm = this.$t('document.confirm',
        {
          prefix: this.prefix,
          type: this.type,
          operation: (archived ? this.$t('document.set_back').toLowerCase() : this.$t('document.archive').toLowerCase()),
          addon: (archived ? unarchive : archive)
        })
      this.$confirm(confirm, this.$t('global.attention'), {
        confirmButtonText: archived ? this.$t('document.set_back') : this.$t('document.archive'),
        cancelButtonText: this.$t('global.cancel'),
        type: 'warning',
        roundButton: true,
        center: true
      })
        .then(_ => {
          // when editing a document switch that off when archiving
          if (archived && this.type === 'document') {
            this.$store.dispatch('documents/edit', false)
          }
          let id = this.type === 'folder' ? this.folder.id : this.document.id
          // the current slug is used to update the current folder the user is on to display updates
          let data = { id: id, archive: !archived, slug: this.last(this.$route.params.slug.split('/')) }
          this.$store.dispatch(this.type + 's/archive', data)
            .then(response => {
              if (response.ok) {
                let archivedText = this.$t('document.can_be_edited', { type: this.type })
                let unarchiveText = this.$t('document.read_only', { type: this.type })
                this.$notify({
                  type: (archived ? 'warning' : 'success'),
                  title: this.$t('messages.success'),
                  message: (archived ? archivedText : unarchiveText),
                  position: 'bottom-right'
                })
              }
            })
            .catch(_ => {
              this.$notify.error({
                title: this.$t('messages.error'),
                message: this.$t('messages.generic_help_tooltip'),
                position: 'bottom-right'
              })
            })
        })
    }
  }
}
</script>

<style lang="scss">
.archive-button {
  .el-button {
    margin: 15px 0px;
    width: 100%;
    font-weight: bold;
    margin-bottom: 75px;

    @include media(">tablet") {
      margin: 0;
    }

    & svg {
      vertical-align: middle;
    }

    & .text {
      vertical-align: middle;
      margin-left: 10px;
    }
  }
}
</style>
