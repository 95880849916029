<template>
  <div class="document-switch">
    <span :class="edit ? 'under-hover active' : 'under-hover'"></span>
    <div
      @click="setEdit(false)"
      :class="!edit ? 'left active' : 'left'"
    >
      <i class='el-icon-view'></i> {{$t('global.view')}}
    </div>
    <div
      @click="setEdit(true)"
      :class="edit ? 'right active' : 'right'"
    >
      <i class='el-icon-edit'></i> {{$t('global.edit')}}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'document-switch',
  computed: mapState({
    edit: state => state.documents.edit
  }),
  methods: {
    setEdit (value) {
      this.$store.dispatch('documents/edit', value)
    }
  }
}
</script>

<style lang="scss">
.document-switch {
  position: relative;
  height: 36px;
  margin-bottom: 20px;
  background-color: #3d4db7;
  display: flex;
  justify-content: space-between;
  border-radius: 25px;
  flex-grow: 1;
  padding: 2px;

  .under-hover {
    @include border-radius(25px);
    @include transition(all 0.6s ease);
    background-color: #fff;
    border: 2px solid #3d4db7;
    width: 50%;
    position: absolute;
    height: 91%;
    top: 0px;
    left: -1px;
    padding: 0px;

    &.active {
      left: 50%;
    }
  }

  div {
    @include transition(all 0.6s ease);
    position: absolute;
    color: #fff;
    width: 43%;
    text-align: center;
    padding: 10px;
    font-size: 0.9em;
    text-decoration: none;
    font-weight: bold;

    &.left {
      left: 0px;
    }

    &.right {
      right: 0px;
    }

    &:hover {
      cursor: pointer;
    }

    &.blocked:hover {
      cursor: not-allowed;
    }

    &.left,
    &.right {
      @include media(">tablet", "<desktop") {
        font-size: 0.8em;
        padding: 11px 0;
      }
    }

    &.active {
      color: #3d4db7;
    }

    i {
      font-weight: bold;
    }

    @include media(">tablet", "<=desktop") {
      width: 50%;
      i {
        display: none;
      }
    }
  }
}
</style>
