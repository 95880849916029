import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

const newUser = {
  id: null,
  name: null,
  email: null,
  avatar: null,
  password: null,
  password_confirmation: null,
  email_validated: false,
  role: null,
  secret_set: false,
  created_at: null,
  updated_at: null
}

const state = {
  current_user: {
    id: null,
    name: null,
    email: null,
    avatar: null,
    password: null,
    password_confirmation: null,
    email_validated: false,
    role: null,
    secret_set: false,
    created_at: null,
    updated_at: null
  },
  user: {
    id: null,
    name: null,
    email: null,
    avatar: null,
    password: null,
    password_confirmation: null,
    email_validated: false,
    role: null,
    secret_set: false,
    created_at: null,
    updated_at: null
  },
  all: []
}

const getters = {}

const actions = {
  resetUser ({ commit }) {
    return new Promise(function (resolve, reject) {
      commit('setUser', newUser)
      resolve()
    })
  },
  validate ({ commit }, token) {
    return new Promise(function (resolve, reject) {
      Vue.http.get('users/validate/' + token)
        .then(response => {
          if (response.ok) {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch(e => reject(e))
    })
  },
  setCurrentUser ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      commit('setCurrentUser', data)
      resolve()
    })
  },
  getCurrentUser ({ commit, dispatch }, id) {
    return new Promise(function (resolve, reject) {
      dispatch('get', id)
        .then(response => {
          commit('setCurrentUser', response.data.user)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  get ({ commit }, id) {
    return new Promise(function (resolve, reject) {
      Vue.http.get('users/' + id)
        .then(response => {
          commit('setUser', response.data.user)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  all ({ commit }) {
    return new Promise(function (resolve, reject) {
      Vue.http.get('users')
        .then(response => resolve(commit('setAllUsers', response.data.users)))
        .catch(e => reject(e))
    })
  },
  getJWTToken ({ commit, dispatch }, auth) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('user_token', { auth: auth })
        .then(response => {
          if (response.ok && response.data.jwt) {
            resolve(response)
          }
          reject(response)
        })
        .catch(e => reject(e))
    })
  },
  registerUser ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('users/register', data)
        .then(response => {
          if (response.ok) {
            resolve(response)
          }
          reject(response)
        })
        .catch(e => reject(e))
    })
  },
  signInUser ({ commit, dispatch }, userSignIn) {
    return new Promise(function (resolve, reject) {
      dispatch('getJWTToken', userSignIn)
        .then(response => {
          let jwtToken = response.data.jwt
          dispatch('setToken', response.data.jwt)
            .then(_ => {
              let data = JSON.parse(atob(jwtToken.split('.')[1]))
              dispatch('getCurrentUser', data.sub)
                .then(response => {
                  if (response.ok) {
                    resolve(response)
                  }
                  reject(response)
                })
                .catch(e => reject(e))
            })
            .catch(e => reject(e))
        })
        .catch(e => reject(e))
    })
  },
  resetPassword ({ commit }, reset) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('users/reset', reset)
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  resend ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('users/resend', data)
        .then(response => {
          if (response.ok) {
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch(e => reject(e))
    })
  },
  setToken ({ commit }, jwt) {
    localStorage.setItem('jwt', jwt)
    Vue.http.interceptors.push((request, next) => {
      request.headers.set('Authorization', `Bearer ${jwt}`)
      next()
    })
  },
  create ({ commit }, user) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('users', user)
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  save ({ commit }, user) {
    return new Promise(function (resolve, reject) {
      Vue.http.patch('users/' + user.id, user)
        .then(response => {
          commit('setUser', response.data.user)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  delete ({ commit }, id) {
    return new Promise(function (resolve, reject) {
      Vue.http.delete('users/' + id)
        .then(response => {
          commit('setUser', response.data.user)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  // Organizations
  organizationAdd ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('users/' + data.userId + '/organization', { organization_id: data.organizationId })
        .then(response => {
          dispatch('all')
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  organizationDelete ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.delete('users/' + data.userId + '/organization/' + data.organizationId)
        .then(response => {
          dispatch('all')
          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

const mutations = {
  setCurrentUser (state, user) {
    state.current_user = user
  },
  setUser (state, user) {
    state.user = user
  },
  setAllUsers (state, users) {
    state.all = users
  }
}

export default {
  namespaced: true,
  plugins: [
    VuexReset()
  ],
  state,
  getters,
  actions,
  mutations
}
