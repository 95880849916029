<template>
  <div class="register">
    <div class="auth-container">
      <h1>{{$t('authentication.sign_up')}}</h1>
      <el-form
        ref="register"
        :model="register"
        :rules="rules"
        label-position="top"
        @submit.prevent="submitForm"
      >
        <el-form-item
          :label="$t('user.name')"
          prop="name"
        >
          <el-input
            name="name"
            :placeholder="$t('global.fill_in_your_type', { type: $t('user.name')})"
            v-model="register.name"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('user.email')"
          prop="email"
        >
          <el-input
            name="email"
            :placeholder="$t('global.fill_in_your_type', { type: $t('user.email')})"
            v-model="register.email"
            type="email"
          ></el-input>
        </el-form-item>

        <el-row
          type="flex"
          :gutter="20"
        >
          <el-col :span="12">
            <el-form-item
              name="password"
              :label="$t('user.password')"
              prop="password"
            >
              <el-input
                :placeholder="$t('user.password')"
                v-model="register.password"
                type="password"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              name="password_confirmation"
              class="no-label"
              prop="password_confirmation"
            >
              <el-input
                :placeholder="$t('user.password_validation')"
                v-model="register.password_confirmation"
                type="password"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          :label="$tc('global.organization')"
          prop="organization"
        >
          <el-input
            name="organization"
            :placeholder="$t('global.name_of_type', { type: $tc('global.organization')})"
            v-model="register.organization"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="btn-submit"
            @click.prevent="submitForm"
            native-type="submit"
            type="primary"
            round
          >
            <font-awesome-icon :icon="['fas', 'paper-plane']" /> {{$t('authentication.sign_up')}}</el-button>
        </el-form-item>
      </el-form>
      <div class="link-container">
        <router-link
          to="/sign-in"
          class="link"
        >{{$t('authentication.already_got_account')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'register',
  data () {
    var checkDuplicatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('user.password_validation_empty')))
      } else if (value !== this.register.password) {
        callback(new Error(this.$t('user.passwords_not_matching')))
      } else if (value <= 6) {
        callback(new Error(this.$t('user.password_to_short')))
      } else {
        callback()
      }
    }
    return {
      register: { 'name': null, 'email': null, 'password': null, 'password_confirmation': null, 'organization': null, 'user_id': null },
      rules: {
        name: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.name') }) }
        ],
        email: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.email') }) },
          { type: 'email', message: this.$t('global.fill_in_a_valid_type', { type: this.$t('user.email') }) }
        ],
        password: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.password') }) }
        ],
        password_confirmation: [
          { required: true, message: this.$t('user.password_validation_empty') },
          { validator: checkDuplicatePassword }
        ],
        organization: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$tc('global.organization') }) }
        ]
      }
    }
  },
  methods: {
    error (errors) {
      for (let property in errors) {
        errors[property].forEach(line => {
          this.$notify.error({
            title: this.$t('messages.error'),
            message: line || this.$t('messages.generic_help_tooltip'),
            position: 'bottom-right'
          })
        })
      }
    },
    submitForm: function () {
      this.$refs.register.validate((valid) => {
        if (valid) {
          this.$store.dispatch('users/registerUser', this.register)
            .then(response => {
              if (response.ok) {
                this.$notify.success({
                  title: this.$t('messages.success'),
                  message: this.$t('authentication.verification_email_send'),
                  position: 'bottom-right'
                })
              }
            })
            .catch(response => this.error(response.data.errors))
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.el-form-item.no-label {
  margin-top: 50px;
}

.el-button.btn-submit {
  margin-top: 25px;
}
</style>
