import Vue from 'vue'
import VueI18n from 'vue-i18n'

// locales for Element Library
import nlLocale from 'element-ui/lib/locale/lang/nl'
import enLocale from 'element-ui/lib/locale/lang/en'

import en from './en.json'
import nl from './nl.json'

Vue.use(VueI18n)

const defaultLocale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'nl-NL'
const fallbackLocale = 'en-US'
const messages = {
  'en-US': Object.assign(en, enLocale),
  'nl-NL': Object.assign(nl, nlLocale)
}

const dateTimeFormats = {
  'en-US': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true
    }
  },
  'nl-NL': {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
    }
  }
}

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'nl-NL': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  }
}

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: fallbackLocale,
  messages,
  dateTimeFormats,
  numberFormats
})
