
import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

const state = {
  all: []
}

const getters = {}

const actions = {
  query ({ commit }, query) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('search/', { query: query })
        .then(response => {
          commit('setFound', response.data)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

const mutations = {
  setFound (state, found) {
    state.all = found
  }
}

export default {
  namespaced: true,
  plugins: [
    VuexReset()
  ],
  state,
  getters,
  actions,
  mutations
}
