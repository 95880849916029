import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

const actions = {
  check ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('auth/check', data)
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  enable ({ commit }) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('auth/enable')
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  disable ({ commit }) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('auth/disable')
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  generate ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('auth/generate', data, { headers: { 'Accept': 'image/svg+xml' } })
        .then(response => {
          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

export default {
  namespaced: true,
  plugins: [VuexReset()],
  actions
}
