<template>
  <div class="sign-in">
    <div class="auth-container">
      <h1>{{$t('global.sign_in')}}</h1>
      <el-form
        ref="signIn"
        :model="signIn"
        :rules="rules"
        label-position="top"
        @submit.prevent="submitForm"
      >
        <el-form-item
          :label="$t('user.email')"
          prop="email"
        >
          <el-input
            name="email"
            type="email"
            :placeholder="$t('global.fill_in_your_type', { type: $t('user.email')})"
            v-model="signIn.email"
            autofocus
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('user.password')"
          prop="password"
        >
          <el-input
            type="password"
            name="password"
            :placeholder="$t('global.fill_in_your_type', { type: $t('user.password')})"
            v-model="signIn.password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="btn-submit"
            @click.prevent="submitForm"
            native-type="submit"
            type="primary"
            round
          >
            <font-awesome-icon :icon="['fas', 'sign-in-alt']" /> {{$t('global.sign_in')}}
          </el-button>
        </el-form-item>
      </el-form>
      <div class="link-container">
        <router-link
          to="/register"
          class="link"
        >{{$t('authentication.no_account_yet')}}</router-link>
        <router-link
          to="/forgot-password"
          class="link"
        >{{$t('authentication.forgot_password')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins'

export default {
  name: 'sign-in',
  mixins: [mixin],
  data () {
    return {
      signIn: { email: null, password: null },
      rules: {
        email: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.email') }) },
          { type: 'email', message: this.$t('global.fill_in_a_valid_type', { type: this.$t('user.email') }) }
        ],
        password: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('user.password') }) }
        ]
      }
    }
  },
  methods: {
    submitForm () {
      this.$refs['signIn'].validate((valid) => {
        if (valid) {
          this.$store.dispatch('auth/check', this.signIn)
            .then(response => {
              if (response.ok) {
                if (!response.data.email_validated) {
                  this.signInError(response)
                } else if (response.body.secret_set) {
                  // check if the user has a secret set if so goto two-factor page and pass signIn form and enabled status
                  this.$store.dispatch('users/setCurrentUser', this.signIn)
                    .then(_ => {
                      this.$router.push({ name: 'two_factor', params: { enabled: response.body.enabled } })
                    })
                } else {
                  this.normalLogin()
                }
              }
            })
            .catch(response => this.signInError(response))
        } else {
          return false
        }
      })
    },
    normalLogin () {
      this.$store.dispatch('users/signInUser', this.signIn)
        .then(response => {
          if (response.ok && response.data.user) {
            this.$notify.success({
              title: this.$t('messages.success'),
              message: this.$t('authentication.logged_in'),
              position: 'bottom-right'
            })
            this.$router.push('/documents')
          } else {
            this.message('error', response.data.errors)
          }
        })
        .catch(response => this.signInError(response))
    },
    signInError (response) {
      if (response.data.email_validated === false) {
        this.message('error', this.$t('authentication.verification_not_complete_resend'), () => {
          this.$store.dispatch('users/resend', { email: this.signIn.email })
            .then(response => {
              this.message('success', this.$t('authentication.email_resend'))
            })
            .catch(response => {
              this.error()
            })
        })
      } else {
        let errors = [this.$t('authentication.combination_not_found')]
        this.message('error', errors)
      }
    }
  }
}
</script>

<style lang="scss">
.signIn {
  .auth-container {
    .el-form {
      margin-top: 42px;
    }
  }
}
</style>
