<template>
  <el-popover
    class="two-factor-tooltip"
    placement="right-start"
    title="Hulp met token"
    width="320"
  >
    <div class="qr-popover">
      {{$t('authentication.two_factor_help_text')}}
      <a href="https://play.google.com/store/apps/details?id=com.authy.authy">
        <img
          :src="require('../../assets/images/playstore.svg')"
          alt="Google Playstore Link"
        />
      </a>
      <a href="https://apps.apple.com/us/app/authy/id494168017">
        <img
          :src="require('../../assets/images/appstore.svg')"
          alt="Apple Appstore Link"
        />
      </a>
    </div>
    <div
      class="two-factor-tooltip-reference"
      slot="reference"
    >
      <font-awesome-icon :icon="['fas', 'question-circle']" />
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'two-factor-tooltip'
}
</script>

<style lang="scss">
.two-factor-tooltip-reference:hover {
  color: $template-color-first;
  cursor: pointer;
}

.two-factor-tooltip {
  display: inline-block;
}
.el-popover {
  .el-popover__title {
    text-align: center;
  }
  .qr-popover {
    word-break: normal;
    text-align: center;

    img {
      display: block;
      margin: 15px auto 0 auto;
    }
  }
}
</style>
