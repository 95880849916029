<template>
  <div class="error">
    <div class="center">
      <h1>404 - {{$t('error.page_not_found')}}!</h1>
      <p>{{$t('error.contact_us')}} - <a :href="'mailto:' + email">{{email}}</a></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'error',
  data () {
    return {
      email: process.env.VUE_APP_HELP_EMAIL
    }
  }
}
</script>

<style lang="scss">
.error {
  .center {
    margin: 0 auto;
    text-align: center;
    h1 {
      font-size: 2em;
      margin-top: calc(50vh - 80px);
    }
    p {
      margin-top: 20px;
    }
  }
}
</style>
