<template>
  <div class="save-button">
    <el-button
      type="primiary"
      round
      plain
      @click="save()"
    >
      <font-awesome-icon :icon="['fas', 'save']" />
      {{$t('global.save')}}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'save-button',
  methods: {
    save () {
      this.$emit('save-document')
      this.$store.dispatch('documents/edit', false)
    }
  }
}
</script>

<style lang="scss">
.save-button {
  .el-button {
    width: 100%;
    margin-top: 15px;
    background-color: #3d4db7;
    color: #ffff;
  }
}
</style>
