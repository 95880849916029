<template>
  <div class="organization-dialog">
    <el-dialog
      :title="$tc('global.organization')"
      :visible="dialogVisible"
      :beforeClose="closeDialog"
    >
      <el-row
        class="dialog-container"
        type="flex"
        :gutter="20"
      >
        <el-col
          :xs="24"
          :sm="24"
          :lg="10"
        >
          <el-form
            :rules="rules"
            :model="this.organization"
            ref="organizationForm"
            autocomplete="false"
            @submit.prevent="saveOrganization"
          >
            <el-form-item
              :label="$tc('global.organization')"
              prop="name"
            >
              <el-input
                name="name"
                v-model="organization.name"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :lg="14"
        >
          <el-upload
            drag
            v-show="organization.id"
            class="logo-uploader"
            v-loading="imageUploadLoading"
            :action="image_upload_url"
            :show-file-list="false"
            :on-success="handleLogoSuccess"
            :before-upload="beforeLogoUpload"
            :headers="{ 'Authorization': 'Bearer ' + jwt }"
          >
            <img
              v-if="organization.logo"
              :src="image_url"
              class="logo"
            >
            <i
              v-else
              class="el-icon-upload logo-uploader-icon"
            ></i>
          </el-upload>
        </el-col>
        <slot name="footer">
          <div class="dialog-footer">
            <span class="dialog-footer">
              <el-button @click="$emit('close-dialog')">{{$t('global.cancel')}}</el-button>
              <el-button
                type="primary"
                @click.prevent="saveOrganization"
                native-type="submit"
              >{{$t('global.save')}}</el-button>
            </span>
          </div>
        </slot>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../mixins'
import { mapState } from 'vuex'

export default {
  name: 'organization-dialog',
  mixins: [mixin],
  props: {
    show: String,
    organization: Object
  },
  data () {
    return {
      showDialog: false,
      rules: {
        name: [
          { required: true, message: this.$t('global.fill_in_name_of_new_type', { type: this.$tc('global.organization') }) }
        ]
      },
      jwt: localStorage.getItem('jwt'),
      imageUploadLoading: false
    }
  },
  computed: mapState({
    dialogVisible: function () { return this.show === 'organization' },
    image_url: function () { return this.getImageOrigin(this.organization.logo) },
    image_upload_url: state => process.env.VUE_APP_DEFAULT_ROOT + '/organizations/' + state.organizations.organization.id + '/logo'
  }),
  methods: {
    handleLogoSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.$store.dispatch('organizations/get', this.organization.id).then(_ => {
        this.$store.dispatch('organizations/all').then(_ => {
          this.$notify.success({
            title: this.$t('messages.success'),
            message: this.$t('organization.logo_uploaded'),
            position: 'bottom-right'
          })
          this.imageUploadLoading = false
        })
      })
    },
    beforeLogoUpload (file) {
      this.imageUploadLoading = true
      const isCorrectFormat = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(file.type)
      const isCorrectSize = file.size / 1024 / 1024 < 5
      if (!isCorrectFormat) {
        this.$notify.error({
          title: this.$t('messages.error'),
          message: this.$t('messages.wrong_file_type_images_tooltip'),
          position: 'bottom-right'
        })
      }
      if (!isCorrectSize) {
        this.$notify.error({
          title: this.$t('messages.error'),
          message: this.$t('messages.image_to_big_tooltip'),
          position: 'bottom-right'
        })
      }
      return isCorrectFormat && isCorrectSize
    },
    saveOrganization () {
      this.$refs.organizationForm.validate((valid) => {
        if (valid) {
          if (this.organization.id) {
            this.$store.dispatch('organizations/save', this.organization)
              .then(response => {
                if (response.ok) {
                  this.$notify.success({
                    title: this.$t('messages.success'),
                    message: this.$t('global.type_is_saved', { type: this.$tc('global.organization') }),
                    position: 'bottom-right'
                  })
                  this.closeDialog()
                }
              })
              .catch(_ => this.error())
          } else {
            this.$store.dispatch('organizations/create', this.organization)
              .then(response => {
                if (response.ok) {
                  this.$notify.success({
                    title: this.$t('messages.success'),
                    message: this.$t('global.type_is_created', { type: this.$tc('global.organization') }),
                    position: 'bottom-right'
                  })
                  this.closeDialog()
                }
              })
              .catch(_ => this.error())
          }
        }
      })
    },
    closeDialog () {
      this.$emit('close-dialog')
      this.$store.dispatch('organizations/all')
    },
    showError () {
      this.$notify.error({
        title: this.$t('messages.error'),
        message: this.$t('messages.generic_help_tooltip'),
        position: 'bottom-right'
      })
    }
  }
}
</script>

<style lang="scss">
.dialog-container {
  flex-wrap: wrap;
}

.organization-dialog {
  .el-dialog {
    width: 95%;

    @include media(">=desktop") {
      width: 45%;
    }

    @include media(">=tablet", "<desktop") {
      width: 65%;
    }

    .logo-uploader {
      max-width: 256px;
      margin: 0 auto;

      @include media(">tablet") {
        padding: 40px;
      }

      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: 100%;
      }
      .el-upload-dragger:hover {
        border-color: #409eff;
      }
      .logo-uploader-icon {
        color: #8c939d;
        width: 100%;
        line-height: 178px;
        text-align: center;
      }
      .logo {
        width: 100%;
        display: block;
      }
    }

    .dialog-footer {
      width: 100%;

      @include media(">tablet") {
        margin: 0 10px;
      }

      .el-button {
        width: 100%;
        margin: 10px 0 0 0;

        @include media(">tablet") {
          width: auto;
          margin: initial;

          & + .el-button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
