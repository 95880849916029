import Vue from 'vue'
import VuexReset from '@ianwalter/vuex-reset'

const state = {
  folder: {
    id: null,
    orgnanization_id: null,
    user_id: null,
    name: null,
    size: null,
    folder_id: null,
    visible: false,
    archive_number: null,
    archived_at: null,
    created_at: null,
    updated_at: null,
    documents: []
  },
  all: []
}

const getters = {}

const actions = {
  get ({ commit }, id) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .get('folders/' + id)
        .then(response => {
          commit('setFolder', response.data.folder)
          resolve(response.data.folder)
        })
        .catch(e => reject(e))
    })
  },
  all ({ commit }) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .get('folders')
        .then(response => {
          commit('setFolders', response.data.folders)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  slug ({ commit }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('folders/slug', data)
        .then(response => {
          commit('setFolder', response.data.folder)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  create ({ commit, dispatch }, folder) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .post('folders', folder)
        .then(response => {
          dispatch('all')
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  save ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .patch('folders/' + data.id, data.folder)
        .then(response => {
          dispatch('all')
          resolve(response)
        })
        .catch(e => reject(e))
    })
  },
  delete ({ commit, dispatch }, id) {
    return new Promise(function (resolve, reject) {
      Vue.http
        .delete('folders/' + id)
        .then(response => {
          if (response.ok && !response.response) {
            dispatch('all')
            resolve(response)
          }
          reject(response)
        })
        .catch(e => reject(e))
    })
  },
  archive ({ commit, dispatch }, data) {
    return new Promise(function (resolve, reject) {
      Vue.http.post('folders/' + data.id + '/archive', data)
        .then(response => {
          dispatch('slug', data.slug)
          resolve(response)
        })
        .catch(e => reject(e))
    })
  }
}

const mutations = {
  setFolder (state, folder) {
    state.folder = folder
  },
  setFolders (state, folders) {
    state.all = folders
  }
}

export default {
  namespaced: true,
  plugins: [VuexReset()],
  state,
  getters,
  actions,
  mutations
}
