<template>
  <div
    v-loading="loading"
    class="organizations"
  >
    <user-dialog
      @close-dialog="showDialog()"
      :show="dialog"
      :user="user"
    ></user-dialog>
    <organization-dialog
      @close-dialog="showDialog()"
      :show="dialog"
      :organization="organization"
    >
    </organization-dialog>
    <div class="organizations-container">
      <div class="label-container">
        <el-button
          round
          type="primary"
          size="mini"
          class="create-button"
          @click='createOrganization()'
        >
          {{$t('global.create')}}
        </el-button>
        <p>{{$tc('global.organization')}}</p>
        <p class="hidden-xs-only">{{$tc('global.document', 2)}}</p>
        <p class="hidden-xs-only">{{$tc('global.user', 2)}}</p>
        <p class="hidden-sm-and-down">{{$t('global.created_on')}}</p>
      </div>
      <dropdown
        class="no-select"
        v-for="(organization, index) in organizations"
        :key="organization.organization.id"
        :activeState='isActive(organization)'
        :defaultActive="index === 0"
      >
        <div
          class="title"
          slot="header"
        >
          <div>
            <img
              :src="getImageOrigin(organization.organization.logo, require('../assets/images/organization.png'))"
              alt="organization image"
            />
            <p>{{organization.organization.name}}</p>
          </div>
          <div class="hidden-xs-only">{{organization.organization.documents_amount || 0}}<span class="hidden-xs-only text-push">{{$tc('global.document', organization.organization.documents_amount)}}</span></div>
          <div class="hidden-xs-only">{{organization.organization.users_amount || 0}}<span class="hidden-xs-only text-push">{{$tc('global.user', organization.organization.users_amount)}}</span></div>
          <div class="hidden-sm-and-down">{{$d(new Date(organization.organization.created_at), 'long', locale)}}</div>
        </div>
        <div
          class="table-holder do-select"
          slot="content"
        >
          <el-table
            :data="organization.organization.users"
            style="100%"
            :stripe="true"
            :default-sort="{ prop: user.name }"
          >
            <el-table-column
              :label="$t('user.name')"
              min-width="160"
            >
              <template slot-scope="scope">
                {{scope.row.user.name}}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('user.email')"
              min-width="160"
            >
              <template slot-scope="scope">
                {{scope.row.user.email}}
              </template>
            </el-table-column>
            <el-table-column :label="$t('user.role')">
              <template slot-scope="scope">
                {{capitalizeFirstLetter(translateRole(scope.row.user.role))}}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('global.created_on')"
              min-width="160"
            >
              <template slot-scope="scope">
                {{ $d(new Date(scope.row.user.created_at), 'long', locale) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right">
              <template slot-scope="scope">
                <el-button-group>
                  <el-button
                    size="mini"
                    @click="editUser(organization.organization.users[scope.$index].user.id)"
                  >
                    {{$t('global.edit')}}
                  </el-button>
                  <el-button
                    v-show="currentUser.id != organization.organization.users[scope.$index].user.id"
                    size="mini"
                    type="danger"
                    @click="deleteUser(organization.organization.users[scope.$index].user.id)"
                  >
                    {{$t('global.delete')}}
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom-options">
            <el-button
              class="bottom-button"
              @click="editOrganization(organization.organization)"
              type="primary"
              round
            >
              {{$t('global.type_edit', { type: $tc('global.organization')})}}
            </el-button>
            <el-button
              class="bottom-button"
              @click="createUser(organization.organization)"
              type="primary"
              round
            >
              {{$t('global.type_create', { type: $tc('global.user')})}}
            </el-button>
          </div>
        </div>
      </dropdown>
    </div>
  </div>
</template>

<script>
import Dropdown from '../components/organizations/Dropdown'
import UserDialog from '../components/organizations/UserDialog'
import OrganizationDialog from '../components/organizations/OrganizationDialog'
import mixin from '../mixins'
import { mapState } from 'vuex'

export default {
  name: 'organizations',
  mixins: [mixin],
  components: {
    Dropdown,
    UserDialog,
    OrganizationDialog
  },
  data () {
    return {
      loading: true,
      active: null,
      dialog: ''
    }
  },
  computed: mapState({
    organizations: state => state.organizations.all,
    currentUser: state => state.users.current_user,
    user: state => state.users.user,
    organization: state => state.organizations.organization,
    locale: state => localStorage.getItem('locale')
  }),
  methods: {
    /* this method allows only one dialog to be shown where null is no dialog */
    showDialog (state = null) {
      this.dialog = state
    },
    createOrganization () {
      this.$store.dispatch('organizations/reset')
        .then(_ => {
          this.showDialog('organization')
        })
        .catch(_ => this.error())
    },
    editOrganization (organization) {
      this.$store.dispatch('organizations/get', organization.id).then(_ => {
        this.showDialog('organization')
      })
    },
    createUser (organization) {
      this.$store.dispatch('users/resetUser')
        .then(response => {
          this.$store.dispatch('organizations/get', organization.id)
            .then(response => {
              if (response.ok) {
                this.showDialog('user')
              }
            })
        })
    },
    editUser (id) {
      this.$store.dispatch('users/get', id)
        .then(_ => { this.showDialog('user') })
    },
    deleteUser (id) {
      this.$confirm(this.$t('global.type_delete_question', { prefix: this.$t('document.prefix_the'), type: this.$tc('global.user') }), this.$t('global.attention'), {
        confirmButtonText: this.$t('global.delete'),
        cancelButtonText: this.$t('global.cancel'),
        type: 'error',
        roundButton: true,
        center: true
      }).then(_ => {
        this.$store.dispatch('users/delete', id).then(response => {
          if (response.ok) {
            this.$store.dispatch('organizations/all')
            this.$notify.success({
              title: this.$t('messages.success'),
              message: this.$t('global.type_is_deleted', { type: this.$tc('global.user') }),
              position: 'bottom-right'
            })
          } else {
            this.message('error')
          }
        }).catch(_ => {
          this.message('error')
        })
      })
    },
    isActive (organization) {
      if (!this.$route.params.query) {
        return
      }
      return organization.organization.name.toLowerCase() === this.$route.params.query.toLowerCase()
    }
  },
  mounted () {
    this.$store.dispatch('organizations/all')
      .then(_ => { this.loading = false })
  }
}
</script>

<style lang="scss">
.organizations {
  background-color: $template-color-background-dark;

  .organizations-container {
    margin: 20px 5px 0 5px;

    .create-button {
      height: 42px;
      margin-top: 5px;

      background-color: $template-color-first;
      border-color: $template-color-first;
      font-family: "Roboto-Bold", Helvetica, sans-serif;
      font-size: 14px;

      &:hover {
        background-color: lighten($template-color-first, 10%);
        border-color: lighten($template-color-first, 10%);
      }
    }

    @include media(">tablet") {
      margin: 50px;
    }

    .title {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;

      div {
        flex: 0 0 100%;
        display: flex;
        align-items: center;

        @include media(">tablet") {
          flex: 0 0 25%;
        }

        .text-push {
          margin-left: 6px;
        }
      }

      & img {
        max-width: 48px;
        margin-right: 19px;
      }
    }

    .label-container {
      display: flex;

      p {
        flex: 0 1 100%;
        color: $template-color-text-light;

        &:nth-child(2) {
          margin-left: 48px;
        }

        // desktop
        @include media(">tablet") {
          flex: 0 0 24.5%;

          &:nth-child(2) {
            margin-left: 48px;
            flex: 0 0 20.5%;
          }
        }

        // tablet
        @include media(">phone", "<992px") {
          flex: 0 0 31%;
          &:nth-child(2) {
            flex: 0 0 24.5%;
          }
        }
      }
    }

    .bottom-options {
      display: flex;
      margin: 10px;
      flex-wrap: wrap;
      justify-content: flex-end;

      .el-button {
        width: 100%;
        margin: 0 0 10px 0;

        @include media(">tablet") {
          width: auto;
          margin: 10px 10px 0 10px;
        }
      }
    }

    /* overrides button style of element */
    .el-table {
      .el-button + .el-button,
      .el-button {
        margin: 2px;
      }
    }

    .el-collapse-item {
      margin-bottom: 20px;

      .el-collapse-item__header {
        @include border-radius(5px);
        padding: 35px;

        &.is-active {
          @include border-radius(5px 5px 0 0);
        }
      }
    }

    .table-holder {
      padding: 8px;

      @include media(">tablet") {
        padding: 16px 32px;
      }
    }
  }
}
</style>
