<template>
  <div class="folder-dialog">
    <el-dialog
      :title="$t('global.type_edit', { type: $tc('global.folder') })"
      :visible.sync="show"
      :beforeClose="closeDialog"
    >
      <el-form
        ref="folder"
        :model="folder"
        :rules="rules"
        label-position="top"
        @submit.prevent="saveFolder"
        autocomplete="false"
      >
        <el-form-item
          :label="$t('global.name')"
          prop="name"
        >
          <el-input
            name="name"
            type="text"
            :placeholder="$t('global.fill_in_your_type', { type: $t('global.name')})"
            v-model="folder.name"
          ></el-input>
        </el-form-item>
        <div class="buttons">
          <share-button :folder="folder" />
          <archive-button
            type="folder"
            :folder="folder"
          />
        </div>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="$emit('close-dialog')"
          round
        >{{$t('global.cancel')}}</el-button>
        <el-button
          @click.prevent="saveFolder"
          native-type="submit"
          type="primary"
          round
        >{{$t('global.save')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '../../mixins'
import ShareButton from '../share/ShareButton'
import ArchiveButton from '../share/ArchiveButton'

export default {
  name: 'folder-dialog',
  mixins: [mixin],
  props: {
    show: Boolean,
    folder: Object
  },
  components: {
    ShareButton,
    ArchiveButton
  },
  data () {
    return {
      rules: {
        name: [
          { required: true, message: this.$t('global.fill_in_your_type', { type: this.$t('global.name') }) }
        ]
      }
    }
  },
  methods: {
    saveFolder () {
      this.$refs['folder'].validate((valid) => {
        if (valid) {
          this.$store.dispatch('folders/save', {
            id: this.folder.id,
            folder: { name: this.folder.name }
          })
            .then(_ => {
              this.$notify.success({
                title: this.$t('messages.success'),
                message: this.$t('global.type_is_saved', { type: this.$tc('global.user') }),
                position: 'bottom-right'
              })
              this.closeDialog()
            })
        }
      })
    },
    closeDialog () {
      this.$emit('close-dialog')
      let lastSlug = this.last(this.$route.params.slug.split('/'))
      let parentSlug = this.$route.params.slug.split('/').slice(-2)[0]
      let parentSlugFinal = parentSlug === lastSlug ? null : parentSlug
      this.$store.dispatch('folders/slug', { slug: lastSlug, parent_slug: parentSlugFinal })
    }
  }
}
</script>

<style lang="scss">
.folder-dialog {
  .el-dialog {
    width: 95%;

    @include media(">=tablet") {
      .buttons {
        display: flex;
        justify-content: space-between;

        .share-button,
        .archive-button {
          flex: 0 0 45%;

          @include media(">desktop") {
            flex: 0 0 40%;
          }
        }
      }
    }

    @include media(">tablet", "<=desktop") {
      width: 50%;
    }

    @include media(">desktop") {
      width: 30%;
    }
  }
}
</style>
